const tourIcon = (color: string = "#000000") => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="93%" viewBox="0 0 527 490" fill="none">
    <path d="M468.8 358.04L468.76 406.88C468.762 409.902 468.004 412.875 466.555 415.527C465.106 418.178 463.013 420.423 460.469 422.052C457.925 423.682 455.011 424.645 451.996 424.853C448.982 425.06 445.964 424.506 443.22 423.24L425.46 415.06C429 411.28 432.5 407.4 435.92 403.46C448.274 389.391 459.276 374.191 468.78 358.06L468.8 358.04ZM0.639893 18C0.640677 14.9801 1.40126 12.0089 2.85161 9.36005C4.30195 6.7112 6.39543 4.46982 8.93928 2.84231C11.4831 1.2148 14.3956 0.253483 17.4084 0.0468758C20.4213 -0.159731 23.4377 0.395014 26.1799 1.66003L159.34 63.1L303.22 1.46002C307.104 -0.208011 311.451 -0.455818 315.5 0.760022L317.86 1.66003L458.28 66.46C461.405 67.9017 464.051 70.209 465.905 73.1086C467.759 76.0082 468.743 79.3784 468.74 82.82V228.36C458.861 217.01 446.548 208.034 432.72 202.1V94.32L310.02 37.7L166.16 99.36C163.845 100.351 161.347 100.846 158.829 100.811C156.31 100.777 153.827 100.214 151.54 99.16L36.6399 46.12V330.54L159.34 387.18L297.54 327.96C301.86 339.92 307.9 352.06 315.46 364.28L310.02 361.76L166.16 423.44C162.279 425.097 157.94 425.337 153.9 424.12L151.54 423.24L11.0999 358.42C7.97789 356.98 5.33368 354.676 3.48002 351.78C1.62635 348.884 0.640786 345.518 0.639893 342.08V18Z" fill={color}/>
    <path d="M292.3 309.74C295.8 326.1 302.8 342.9 312.84 359.9C310.479 360.231 308.076 360.091 305.77 359.486C303.464 358.881 301.301 357.824 299.407 356.377C297.513 354.929 295.925 353.119 294.736 351.053C293.547 348.987 292.78 346.705 292.48 344.34L292.34 342.08L292.3 309.74ZM310.34 1.65734e-07C314.723 -0.000594291 318.955 1.59798 322.243 4.49586C325.532 7.39373 327.65 11.3918 328.2 15.74L328.34 18L328.32 213.2C310.52 226.14 297.54 244.54 292.32 265.6V18C292.32 13.2261 294.216 8.64773 297.592 5.27208C300.968 1.89642 305.546 1.65734e-07 310.32 1.65734e-07H310.34ZM159.1 64.8C163.486 64.8 167.721 66.4014 171.009 69.3033C174.298 72.2053 176.414 76.2081 176.96 80.56L177.1 82.82V406.88C177.105 411.461 175.363 415.872 172.229 419.214C169.095 422.555 164.804 424.576 160.232 424.864C155.66 425.152 151.15 423.686 147.621 420.764C144.092 417.843 141.81 413.686 141.24 409.14L141.1 406.88V82.82C141.1 78.0461 142.996 73.4677 146.372 70.0921C149.748 66.7164 154.326 64.82 159.1 64.82V64.8Z" fill={color}/>
    <path d="M303.24 1.46002C307.124 -0.208011 311.471 -0.455818 315.52 0.760022L317.88 1.66003L388.08 34.06C392.218 35.9882 395.47 39.4171 397.175 43.6522C398.88 47.8874 398.913 52.6124 397.266 56.8705C395.618 61.1286 392.414 64.6016 388.303 66.5863C384.191 68.571 379.479 68.9191 375.12 67.56L373 66.76L310.04 37.7L166.18 99.36C162.302 101.023 157.962 101.271 153.92 100.06L151.56 99.16L81.3399 66.76C77.1462 64.8643 73.8378 61.4342 72.0949 57.1747C70.3519 52.9152 70.3068 48.1498 71.9687 43.8581C73.6306 39.5663 76.8733 36.0741 81.0304 34.0993C85.1875 32.1244 89.9431 31.817 94.3199 33.24L96.4199 34.06L159.36 63.1L303.24 1.46002ZM297.56 327.96C301.88 339.9 307.9 352.04 315.46 364.24L310.06 361.76L166.18 423.44C162.299 425.097 157.96 425.337 153.92 424.12L151.56 423.24L81.3399 390.84C77.1462 388.944 73.8378 385.514 72.0949 381.255C70.3519 376.995 70.3068 372.23 71.9687 367.938C73.6306 363.646 76.8733 360.154 81.0304 358.179C85.1875 356.204 89.9431 355.897 94.3199 357.32L96.4199 358.12L159.36 387.18L297.56 327.96Z" fill={color}/>
    <path d="M390.28 157.4C315.12 157.4 253.76 214.94 253.76 286.52C253.76 333.88 277.8 381.22 317.4 427C330.92 442.64 345.36 457 359.82 469.8L367.14 476.14L373.86 481.7C376.46 483.8 378.38 485.3 379.46 486.1C382.576 488.437 386.365 489.7 390.26 489.7C394.155 489.7 397.944 488.437 401.06 486.1L406.66 481.7L413.4 476.14C415.74 474.14 418.2 472.04 420.72 469.78C435.789 456.465 449.96 442.168 463.14 426.98C502.74 381.22 526.8 333.88 526.8 286.52C526.8 214.94 465.4 157.4 390.28 157.4ZM390.28 193.4C446.04 193.4 490.8 235.36 490.8 286.52C490.8 323.42 470.4 363.58 435.92 403.44C423.52 417.78 410.18 431.04 396.86 442.84L393.44 445.84L390.24 448.52L383.68 442.84C369.806 430.582 356.757 417.42 344.62 403.44C310.16 363.58 289.76 323.44 289.76 286.52C289.76 235.36 334.5 193.4 390.28 193.4Z" fill={color}/>
    <path d="M390.28 230.96C407.471 230.96 423.959 237.789 436.115 249.945C448.271 262.101 455.1 278.589 455.1 295.78C455.1 312.971 448.271 329.459 436.115 341.615C423.959 353.771 407.471 360.6 390.28 360.6C373.089 360.6 356.601 353.771 344.445 341.615C332.289 329.459 325.46 312.971 325.46 295.78C325.46 278.589 332.289 262.101 344.445 249.945C356.601 237.789 373.089 230.96 390.28 230.96ZM390.28 266.96C382.636 266.96 375.306 269.996 369.901 275.401C364.496 280.806 361.46 288.136 361.46 295.78C361.46 303.424 364.496 310.754 369.901 316.159C375.306 321.564 382.636 324.6 390.28 324.6C397.924 324.6 405.254 321.564 410.659 316.159C416.064 310.754 419.1 303.424 419.1 295.78C419.1 288.136 416.064 280.806 410.659 275.401C405.254 269.996 397.924 266.96 390.28 266.96Z" fill={color}/>
    </svg>
  );
};

export default tourIcon;
