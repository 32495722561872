
import React from 'react';
import './ExitTourModal.css'
import cross from '@gigauser/common/src/assets/svgs/cross';
import { totalmem } from 'os';

type ExitTourModalProps = {
    
};

export const createExitTourModal = (
    closeExitTourModal: (e: MouseEvent) => void,
    endTour: () => void
) => {

    const modalParent = document.createElement("div")
    modalParent.id = "gigauser-ExitTourModal-Parent"

    const gigauserExitTourModal = document.createElement("div")
    gigauserExitTourModal.className = "gigauser-ExitTourModal"

    modalParent.appendChild(gigauserExitTourModal)

    const title = document.createElement("span")
    title.innerHTML = "Are you sure you would like to exit the tour?"

    const gigauserExitButtons = document.createElement("div")
    gigauserExitButtons.className = "gigauser-exit-buttons"

    gigauserExitTourModal.appendChild(title)
    gigauserExitTourModal.appendChild(gigauserExitButtons)

    const exitTourButton = document.createElement("button")
    exitTourButton.className = "gigauser-exit-button gigauser-exit"
    exitTourButton.id = "gigauser-exit"
    exitTourButton.innerHTML = "Exit tour"

    const cancelButton = document.createElement("button")
    cancelButton.className = "gigauser-exit-button gigauser-cancel"
    cancelButton.id="gigauser-cancel"
    cancelButton.innerHTML = "Cancel"

    gigauserExitButtons.appendChild(exitTourButton)
    gigauserExitButtons.appendChild(cancelButton)

    const a =  `
    <div class="gigauser-ExitTourModal">

        Are you sure you would like to exit the tour?

        <div class='gigauser-exit-buttons'>
            <button class='gigauser-exit-button gigauser-exit' id='gigauser-exit'>Exit tour</button>
            <button class='gigauser-exit-button gigauser-cancel' id='gigauser-cancel'>Cancel</button>
        </div>

    </div>    
    `
    // modalParent.innerHTML = a

    cancelButton.onclick = closeExitTourModal
    exitTourButton.onclick = endTour
  
    return {modalParent: modalParent, clickEnabledElements: [cancelButton, exitTourButton ]}


}


const ExitTourModal:React.FC<ExitTourModalProps> = () => {

    //Just for testing purposes. 
    
    return (
        <div className="gigauser-ExitTourModal">
            Are you sure you would like to exit the tour? 

            <div className='gigauser-exit-buttons'>
                <button className='gigauser-exit-button gigauser-exit'>Exit tour</button>
                <button className='gigauser-exit-button gigauser-cancel'>Cancel</button>
            </div>


        </div>
    )
}
export default ExitTourModal;