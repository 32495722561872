import { Voice, VoiceEngine } from "../core/types/guide"
import us from '../assets/svgs/flag_us'
import uk from '../assets/svgs/flag_uk'
import aus from '../assets/svgs/flag_aus'

type Accent =  "American" | "Australian" | "British" | "Canadian"

export type FrontendVoice = {
    displayName: string, 
    description?: string,
    gender: "male"|"female"|"neutral", 
    accent: Accent,
    sample?:string,
    engine: VoiceEngine, 
    id: string
}

export const googleVoices : { [key: string]: FrontendVoice } = {

    "en-US-Neural2-F": {
        displayName: "Google 1", 
        description: "Neutral robotic",
        gender: "female", 
        accent: "American", 
        engine: "google", 
        id: "en-US-Neural2-F",
    }, 
    "en-US-Neural2-A": {
        displayName: "Google 2", 
        description: "Neutral robotic",
        gender: "male", 
        accent: "American", 
        engine: "google", 
        id: "en-US-Neural2-A",
    }, 
    "en-AU-Neural2-C": {
        displayName: "Google 3", 
        description: "Neutral robotic",
        gender: "female", 
        accent: "Australian", 
        engine: "google", 
        id: "en-AU-Neural2-C",
    }, 

}

export const playVoices: { [key: string]: FrontendVoice } = {
    "s3://peregrine-voices/donna_parrot_saad/manifest.json": {
        displayName: "Donna",
        description: "Clueso recommended", 
        accent: "American", 
        gender: "female", 
        sample: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/donna+narrative.wav",
        engine: "play", 
        id: "s3://peregrine-voices/donna_parrot_saad/manifest.json"
    }, 
    "s3://mockingbird-prod/william_vo_narrative_0eacdff5-6243-4e26-8b3b-66e03458c1d1/voices/speaker/manifest.json":{
        displayName: "William",
        description: "Slow instructor", 
        accent: "American", 
        gender: "male", 
        sample: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/william_vo_narrative.wav",
        engine: "play", 
        id: "s3://mockingbird-prod/william_vo_narrative_0eacdff5-6243-4e26-8b3b-66e03458c1d1/voices/speaker/manifest.json"

    },
    "arthur":{
        displayName: "Arthur", 
        description: "Enthusiastic marketing", 
        accent: "British", 
        gender: "male", 
        sample: "https://peregrine-samples.s3.amazonaws.com/editor-samples/arthur.wav",
        engine: "play", 
        id: "arthur",

    },
    "s3://peregrine-voices/evelyn 2 saad parrot/manifest.json": {
        displayName: "Evelyn", 
        description: "Enthusiastic instructor", 
        gender: "female", 
        accent: "American", 
        sample: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/evelyn.wav",
        engine: "play",
        id: "s3://peregrine-voices/evelyn 2 saad parrot/manifest.json",

    }, 
    "s3://peregrine-voices/oliver_narrative2_parrot_saad/manifest.json": {
        displayName: "Oliver",
        description: "Enthusiastic instructor", 
        accent: "British", 
        gender: "male", 
        sample: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/oliver+narrative.wav",
        engine: "play", 
        id: "s3://peregrine-voices/oliver_narrative2_parrot_saad/manifest.json"

    }, 
    "larry": {
        displayName: "Larry", 
        description: "Soft marketing", 
        accent: "American", 
        gender: "male", 
        sample: "https://peregrine-samples.s3.amazonaws.com/editor-samples/larry.wav",
        engine: "play",
        id: "larry",
        
    },
    
}

export const premiumVoices = Object.entries(playVoices).map(([key, value]) => ({ ...value, id:key }))
export const basicVoices = Object.entries(googleVoices).map(([key, value]) => ({ ...value, id:key }))

export const allVoices =  [
    ...Object.entries(playVoices).map(([key, value]) => ({ ...value, id:key })), 
    ...Object.entries(googleVoices).map(([key, value]) => ({ ...value, id:key })),
];


export const getBackendVoice : (frontendVoice: FrontendVoice) => Voice = (frontendVoice) =>{
    return {
        engine: frontendVoice.engine, 
        code: frontendVoice.id, 
        language: `en-${getFlag(frontendVoice.accent).code}`
    }
}

export const getFrontendVoice = (voice: Voice) =>{
    if(voice.engine==="google"){
        return googleVoices[voice.code]
    }
    else if(voice.engine==="play"){
        return playVoices[voice.code]
    }
}

export const getFlag = (accent: Accent ) =>{

    if(accent==="American") return {code: "US", flag: us()}
    else if(accent==="Australian") return {code: "AU", flag: aus()}
    else if(accent==="British") return {code: "UK", flag: uk()}
    else if(accent==="Canadian") return {code: "CA", flag: us()}
    else return {code: "US", flag: us()}

}