import * as React from 'react';
import { Collection } from '../../core/types/collections';
import "./CollectionList.css"
import Icon from "../../ui/Icon/Icon";
import downArrowheadIcon from '../../assets/svgs/downArrowheadIcon';
import CollectionBox from './CollectionBox/CollectionBox';
import { GigaUserApi } from '@giga-user-fern/api';
import { useState } from 'react';
import logger from '../../utils/logger';

export interface CollectionListProps {
    collections: Collection[],
    onOpen: (x: Collection) => void
    editable?: true
    loading?:boolean
}

export default function CollectionList (props: CollectionListProps) {


    logger.debug("collections are: ", props.collections)
    const[viewAll, setViewAll] = useState(false)

    if(props.loading){

        var dummyCollection: (id: string) => Collection = (id) => {
            
            return{
                id: GigaUserApi.Id(id),
                name: "Collection name", 
                description: "Collection description here",
                sequenceNumber: 1
            }
        }

        var collections: Collection[] = [dummyCollection("1"), dummyCollection("2"), dummyCollection("3")]

    }
    else{
        if(viewAll){
            var collections = props.collections
        }
        else if(props.collections){
            var collections = props.collections.slice(0,4)
        }
        else{
            collections = []
        }
    }

  return (
    <div className="gigauser-CollectionList">
            <div className={`gigauser-CollectionList-collections ${props.loading?"gigauser-CollectionList-loading":""}`}>
                {props.collections.map((collection: Collection, index) => {
                    return (
                        <CollectionBox loading={props.loading} onOpen={props.onOpen} collection={collection}></CollectionBox>
                        // <div
                        //     className={`gigauser-CollectionPreview ${
                        //         index === 0 ? "firstRow" : ""
                        //     } ${
                        //         index === props.collections.length - 1
                        //             ? "lastRow"
                        //             : ""
                        //     }`}
                        //     onClick={() => props.onOpen(collection)}
                        // >
                        //     <div className='gigauser-collectionlist-header'>
                        //         <div className="gigauser-CollectionName">
                        //             {collection.name}
                        //         </div>
                        //         <div className="gigauser-CollectionDescription">
                        //             {collection.description}
                        //         </div>
                        //     </div>
                        //     <Icon className="gigauser-Preview-arrow">
                        //         {downArrowheadIcon()}
                        //     </Icon>
                        // </div>
                    );
                })}
            </div>
            {
                props.collections && props.collections.length>4?
                <div className="gigauser-viewAll" onClick={() =>{setViewAll(!viewAll)}}>
                    VIEW {viewAll?"LESS":"ALL"}
                </div>
                :null
            }
            
    </div>
  );
}
