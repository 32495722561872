import React from 'react';
import { Collection } from '../../../core/types/collections';
import './CollectionBox.css';
import Icon from '../../../ui/Icon/Icon';
import downArrowheadIcon from '../../../assets/svgs/downArrowheadIcon';
import folder_color from '../../../assets/svgs/folder_color';

type CollectionBoxProps = {
    collection: Collection,
    onOpen: (x: Collection) => void, 
    loading?:boolean,
};

const CollectionBox:React.FC<CollectionBoxProps> = (props) => {

    const {collection} = props
    
    const onOpen = () =>{
        props.onOpen(props.collection)
    }
    
    return (
        <div className={`gigauser-CollectionBox ${props.loading?"gigauser-CollectionBox-loading":"gigauser-CollectionBox-notloading"}`} onClick={onOpen}>
            <div className='gigauser-CollectionBox-columns'>

                <Icon className="gigauser-CollectionBox-folder">{folder_color}</Icon>
                
                <div className="gigauser-CollectionBox-text">
                    <div className="gigauser-CollectionBox-title">{collection.name}</div>
                    <div className="gigauser-CollectionBox-description">{collection.description}</div>
                </div>

                <Icon className="gigauser-CollectionBox-arrow">{downArrowheadIcon()}</Icon>

            </div>
            

        </div>
    )
}
export default CollectionBox;