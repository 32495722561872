import { findElement, getDomElementDataFromTarget } from "../findElement";
import { TourStep } from "../../types/record";
import { createSurroundingDivs, getBody } from "../../utils/DOMUtils";
import "../tour.css";
import { BaseStep } from "./BaseStep";
import { createNextMessage } from "../message/messages";

export class ForceNext extends BaseStep {
    nextOption: HTMLElement | null
    constructor(step: TourStep, stepIndex: number, options: {editor: boolean}){
        super(step, stepIndex, options)
        this.nextOption = null
    }

    initiateTarget = () => {
        if (!this.target) return;
        this.target.addEventListener("mouseleave", this.disableClicks);
        this.nextOption?.addEventListener("click", this.completeStep);
    };

    createControls = () => {
        if (!this.blockingContainer) return;
        // const controlDiv = document.createElement("div");
        // controlDiv.className =
        //     "gigauser-guided-message" + (this.editor ? " editor" : "");
      
        // controlDiv.innerText = this.step.metadata.text;
        
        // const endButton = document.createElement("button");
        // endButton.onclick = this.removeStep;
        // endButton.innerText = "End Tour";
        // endButton.className = "gigauser-end-guided-tour";
        // controlDiv.appendChild(endButton);
        // this.endButton = endButton;
        // this.clickEnabled.push(this.endButton)
        

        // this.nextOption = document.createElement("button");
        // this.nextOption.innerText = "Next Step"
        // this.nextOption.className = "gigauser-end-guided-tour";
        // this.nextOption.style.backgroundColor = "green"
        // this.clickEnabled.push(this.nextOption)
        // controlDiv.appendChild(this.nextOption)
        // this.controls = controlDiv;
        // this.blockingContainer.appendChild(controlDiv);
        const {next, end, controls} = createNextMessage(this.step, this.stepIndex, this.target!, this.blockingContainer, {nextButton: true})
        //@ts-expect-error
        this.nextOption = next
        this.clickEnabled.push(this.nextOption!)
        this.endButton = end
        this.endButton.onclick = this.removeStep
        this.clickEnabled.push(this.endButton)
        this.controls = controls
    };

    

    removeStep = () => {
        this.endTour()
    };



    playStep = async () => {

        const elem = await this.canPlay()
        if (elem) {
            // About to run a step!
            // Set the element
            this.target = elem;

            // Make everything unclickable
            this.createBlockingContainer();

            // Create the flashing box that serves a hole
            this.createBoundingBox();

            // Create step text
            this.createControls();

            // Set listeners on target
            this.initiateTarget();
            
            this.handleTourClicks()
            return 
        }

        
    };
}
