
const readIcon = (color?: string) =>{

    const fill = color || "#000000"

    return(
        <svg width="100%" height="80%" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 30.9997C19.0739 30.9783 17.6506 30.0107 16.7241 30.0107H1V1H16.7241C17.7069 1 19.0172 1.989 20.097 1.989C20.9828 1.989 22.2931 1 23.2759 1H39V30.0107H23.2759C22.3914 30.0107 20.8845 31.0201 20 30.9997ZM20 30.9997V2.978" stroke={fill} strokeWidth="2" strokeMiterlimit="5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6H15.6113M6 11H15.6113M6 16H15.6113M24.3887 6H34M24.3887 11H34" stroke={fill} strokeWidth="2" strokeMiterlimit="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
} 

export default readIcon
