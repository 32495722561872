import { useActive, useChainedCommands, useCommands, useRemirrorContext } from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import "./BlockTypeDropdown.css";
type BlockTypeDropdownProps = {};



const BlockTypeDropdown: React.FC<BlockTypeDropdownProps> = () => {
    const mediumGrey = "#4b4b4b";
    const active = useActive();
    const isActive = () => {
        let activeHeading: string | number = "default";
        [1, 2, 3, 4, 5].forEach((level) => {
            if (active.heading({ level: level })) {
                activeHeading = level;
            }
        });
        return activeHeading;
    };
    const commands = useChainedCommands() as any;
    const handler = (level: string | number) => {
        console.log(commands);
        if (level !== "default") {
            commands.toggleHeading({ level }).focus().run();
        } else {
            let activeHeading: string | number = "default";
            [1, 2, 3, 4, 5].forEach((level) => {
                if (active.heading({ level: level })) {
                    activeHeading = level;
                }
            });
            if (activeHeading === "default") return;
            commands.toggleHeading({ level: activeHeading }).focus().run();
        }
    };



    return (
        <div className="gigauser-texteditor-toolbar-blocktype-dropdown" >
            <div className="gigauser-texteditor-toolbar-blocktype-dropdown-heading">
                {isActive() === "default" ? "Regular" : "H" + isActive()}
            </div>

            <div className="gigauser-texteditor-toolbar-blocktype-dropdown-content">
                <div
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler("default");
                    }}
                >
                    Regular
                </div>
                <h1
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler(1);
                    }}
                >
                    H1
                </h1>
                <h2
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler(2);
                    }}
                >
                    H2
                </h2>
                <h3
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler(3);
                    }}
                >
                    H3
                </h3>
                <h4
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler(4);
                    }}
                >
                    H4
                </h4>
                <h5
                    className={`gigauser-texteditor-toolbar-blocktype-dropdown-content-item`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handler(5);
                    }}
                >
                    H5
                </h5>
            </div>
        </div>
    );
};
export default BlockTypeDropdown;
