import { GigaUserApi } from "@giga-user-fern/api"
import { Collection } from "../../core/types/collections"
import { rootCollection } from "../../types/files"
import { GuideData, GuidePreview } from "../../core/types/guide"
import logger from "../../utils/logger"

type GetPathFunction = (id: GigaUserApi.Id) => Promise<false | [Collection, ...Collection[]]>
type FetchGuidePreviewFunction = (id: GigaUserApi.Id) => Promise<GuidePreview | null>

type GetPathFromRouteProps = {
    getPath: GetPathFunction, 
    fetchGuidePreview: FetchGuidePreviewFunction ,
    onLoadingGuide?:() => void,
    onLoadingCollection?:() =>void
}

export const isProdDomain = ()=>{
    if (window.location.hostname !== "localhost" && window.location.hostname !== "preview.clueso.io"){
        return true
    }
}

const getConstructedPath = async(collectionId: string, getPath: GetPathFunction ) =>{

    var newPath : [Collection, ...Collection[]] = [rootCollection]


    if(collectionId==="Collection_root"){
        newPath = [rootCollection]
    }

    else if (collectionId){
        const sections = await getPath(GigaUserApi.Id(collectionId))
        if(sections){
            logger.debug("getPath sections: ", sections)
            newPath = sections as [Collection, ...Collection[]]
        }
        else{
            logger.debug("no sections")
        }
    }

    return newPath

}

export const getPathFromRoute: (props: GetPathFromRouteProps) => 
    Promise<
        false | undefined | 
        {
            type: "folders";
            newPath: [Collection, ...Collection[]];
        } | {
            type: "guide";
            guidePreview: GigaUserApi.guides.GuidePreview;
            newPath: [Collection, ...Collection[]];
        }
    > 
= async(props) =>{

    try{
        const urlSegments = window.location.pathname.split('/').filter(x=>x!=='')

    if (urlSegments.includes('folders')) {

        if(props.onLoadingCollection) props.onLoadingCollection()

        //Folders

        let collectionId = urlSegments.at(-1)
        if(!collectionId) collectionId = ""
        
        const newPath = await getConstructedPath(collectionId, props.getPath)

        return {
            type: "folders", 
            newPath
        }

    }

    else if(urlSegments.includes('guide')) {
        logger.debug("SEGMENTS IS", urlSegments)
        if(props.onLoadingGuide) props.onLoadingGuide()

        const guideId = urlSegments.at(-1)

        if(!guideId) return false

        const guidePreview = await props.fetchGuidePreview(GigaUserApi.Id(guideId)) as GuidePreview
        
        let collectionId = guidePreview.parentId?.toString()
        if(!collectionId) collectionId = ""
        
        const newPath = await getConstructedPath(collectionId, props.getPath)

        return {
            type:"guide", 
            guidePreview, 
            newPath
        }

    }
    }
    catch(e){
        logger.error(e)
        return false
    }

    
    
}