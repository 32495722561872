

const messageIcon = (color: string="#000000") =>{

    return(
        <svg  fill={color} version="1.1" id="Layer_1" viewBox="0 0 24 24" >
        <style type="text/css">
            {/* .st0{fill:none;} */}
        </style>
        <g id="surface1">
            <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z"/>
        </g>
        </svg>
    )
}

export default messageIcon