//@ts-nocheck
import posthog from 'posthog-js'
import { Organization } from '../types/guide';


export type GuideRecordingStarted = {
    eventName: "GuideRecordingStarted"
    value: {}
}
export type GuideRecordingCompleted = {
    eventName: "GuideRecordingCompleted"
    value: {}
}
export type GuideCreated = {
    eventName: "GuideCreated"
    value: {guideID: string}
}

export type GuideUpdated = {
    eventName: "GuideUpdated"
    value: {guideID: string}
}
export type GuideEnhanced = {
    eventName: "GuideEnhanced"
    value: {guideID: string| null}
}

export type GuideOpenedInHelpCenter = {
    eventName: "GuideOpenedInHelpCenter"
    value: {guideID: string}
}
export type NewButtonClicked = {
    eventName: "NewButtonClicked"
    value: {}
}

export type SearchRunInHelpCenter = {
    eventName: "SearchRunInHelpCenter"
    value: {searchString: string}
}

export type SearchReturnsNoResults = {
    eventName: "SearchReturnsNoResults"
    value: {searchString: string}
}

export type AnalyticsEvent = 
    GuideOpenedInHelpCenter | GuideRecordingStarted | GuideRecordingCompleted | GuideCreated | GuideUpdated | NewButtonClicked | GuideEnhanced | SearchRunInHelpCenter | SearchReturnsNoResults


export function initializeAnalytics(org: Organization){
    try{
    posthog.init("phc_K9xtjpstJIE86BCqAq7SLsbsWK31H7YggUQZIf8xf9x", {
        api_host: 'https://app.posthog.com',
        loaded: function (posthog) {
            // posthog.group('company', org.id, {name: org.name})
        },
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
    
    })
    
        // logger.debug("POSTHOG", org.id, org.name)
        posthog.group('company', org.id)
        // Identify group once on sign in instead
    }
    catch(e){
        try{
            logger.error(e)
            posthog.capture("Error", e)
        }
        catch(e){

        }
    }
    
}   
export function groupName(org: Organization){
    try{    
        posthog.group('company', org.id, {name: org.name})
    }
    catch(e){

    }
}

export function captureEvent(event: AnalyticsEvent){
    try{
        posthog.capture(event.eventName, event.value)
    }
    catch(e){
        
    }
}

