// export const createOverlay = ()=>{
//     const body = document.getElementsByTagName('body')[0]
//     const bigDiv = document.createElement('div')
//     bigDiv.style.height = window.innerHeight + "px"
//     bigDiv.style.width = window.innerWidth + "px"
//     bigDiv.style.zIndex = '10000'
//     body.appendChild(bigDiv)
//     return bigDiv
// }
import { findElement, getDomElementDataFromTarget } from "./findElement";
import { EditTourStep, TourStep } from "../types/record";
import { createSurroundingDivs, getBody, showError, showComplete } from '../utils/DOMUtils';
import "./tour.css";
import { BaseStep } from "./steps/BaseStep";
import { DefaultClick } from "./steps/DefaultClick";
import { ForceNext } from "./steps/ForceNext";
import { GigaUserApi } from '@giga-user-fern/api';
import logger from "../../utils/logger";
export class GuidedTour {
    tour: TourStep[];
    totalSteps: number;
    currentIndex: number;
    editor: boolean;
    currentStep?: BaseStep;
    stepCallback?: (currStep: TourStep) => void;
    completeCallback?: ()=>void
    constructor(
        steps: TourStep[],
        options: {
            allowAllClicks: boolean;
            editor: boolean;
            stepCallback?: (currStep: TourStep) => void;
            completeCallback?: ()=>void
        }
    ) {
        // We are going to save some details
        this.tour = steps;
        this.totalSteps = this.tour.length;
        this.currentIndex = 0;
        this.editor = options.editor;
        this.stepCallback = options.stepCallback;
        this.completeCallback = options.completeCallback
    }

    processStep = async () => {
        logger.debug("PROCESSING STEP")

        // Orchestrator
        const step: TourStep = this.tour[this.currentIndex];
        // Set the step
        // Run callback to update state in editor
        if (this.currentIndex === this.totalSteps) {
            //showComplete()
            logger.debug("COMPLETED, CALLBACK STARTING")
            this.completeCallback?.()
            return
        }

        this.stepCallback?.(step);
        if (step.metadata.autoProgressable === "auto") {
            this.currentStep = new DefaultClick(step, this.currentIndex, { editor: this.editor });
        } else {
            this.currentStep = new ForceNext(step, this.currentIndex, { editor: this.editor });
        }

        this.currentStep.completeCallback = () => {
            this.currentStep?.removeStep();
            // Current step will be defined
            delete this.currentStep;
            this.currentIndex += 1;
            this.processStep();
        };

        this.currentStep.restartStep = ()=>{
            // If target disappears/is affected
            logger.debug("RESTARTING STEP")
            this.currentStep?.removeStep();
            this.processStep()
        }
        const interim = document.createElement("div");

        interim.className = "gigauser-clickthrough-opacity";
        interim.style.width = "100%";
        interim.style.height = "100%";
        interim.style.left = "0px";
        interim.style.top = "0px";
        getBody().appendChild(interim);

        const canPlay = await this.currentStep.canPlay();

        getBody().removeChild(interim);

        if (canPlay) {
            // We can move forward
            this.currentStep.playStep();
            return true;
        } else {
            showError();
            this.completeCallback?.()
            return false;
        }
    };

    canStart = async () => {
        const step = this.tour[0];
        if (step.metadata.autoProgressable === "auto") {
            this.currentStep = new DefaultClick(step, this.currentIndex,{ editor: this.editor });
        } else {
            this.currentStep = new ForceNext(step, this.currentIndex, { editor: this.editor });
        }
        const canPlay = await this.currentStep.canPlay();
        return canPlay;
    };

    getStepNumber = () => {
        return this.currentIndex + 1;
    };

    start = () => {
        return this.processStep();
    };

    destroy = () => {
        this.currentStep?.removeStep();
    };

    update = (x: EditTourStep) => {
        if (x.text) {
            this.tour[this.currentIndex].metadata.text = x.text;
        }
        if (x.ancestor !== undefined) {
            this.tour[this.currentIndex].metadata.ancestor = x.ancestor;
        }
        if (x.autoProgressable !== undefined) {
            this.tour[this.currentIndex].metadata.autoProgressable =
                x.autoProgressable;
        }
        if (x.title){
            this.tour[this.currentIndex].metadata.title = x.title
        }
        if(x.customSelector){
            if (x.customSelector.name === ""){
                this.tour[this.currentIndex].metadata.customSelector = undefined
            }
            else{
                this.tour[this.currentIndex].metadata.customSelector = x.customSelector
            }
        }
        this.currentStep?.removeStep();
        delete this.currentStep;
        this.processStep();

        return this.tour;
    };

    nextStep = () => {
        this.currentStep?.removeStep();
        this.currentIndex += 1;
        this.processStep();
    };

    deleteStep = () => {
        this.currentStep?.removeStep();
        this.tour.splice(this.currentIndex, 1);
        this.processStep();
        return this.tour;
    };
}

export const startTour = (steps: TourStep[], completeCallback?: ()=>void) => {
    const newTour = new GuidedTour(steps, {
        allowAllClicks: false,
        editor: false,
        completeCallback: completeCallback
    });
    newTour.start();
};
