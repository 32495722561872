import { Guide, GuideData, GuidePreview } from "@gigauser/common/src/core/types/guide"
import { useContext } from "react"
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext"
import { useAppDispatch, useAppSelector } from "../redux"
import { setCollectionPath, setCurrentGuide } from "../redux/slices/hcSlice"
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics"
import useNavigateToLink from "./useNavigateToLink"
import { selectViewerContainer } from "../redux/slices/hcUiSlice"
import { Collection } from "@gigauser/common/src/core/types/collections"
import { rootCollection } from "@gigauser/common/src/types/files"

const useOpenCollection: () => (collection: Collection, parentSegments: Collection[]) => Promise<void> = () =>{
    /**
     * This hook is to be used only for appending a collection to the path.
     * That is, the new collection to be opened is a child of the current collection
     */
    
    const helpCenterContext = useContext(ExtHelpCenterContext)
    const dispatch = useAppDispatch()
    const navigateToLink = useNavigateToLink()
    const viewerComponent = useAppSelector(selectViewerContainer)
    const collectionPath = useAppSelector(state => state.hc.collectionPath)

    return async (collection: Collection, _parentSegments: Collection[]) => {

        /**
         * @parentSegments may or may not include the collection. Both are OK, we will handle here
        */

        var parentSegments: [Collection, ...Collection[]] = [..._parentSegments] as [Collection, ...Collection[]]

        if(parentSegments[parentSegments.length - 1].id === collection.id){
            //The parent segments contains this collection also, lets get rid of it
            parentSegments = parentSegments.slice(0, -1) as [Collection, ...Collection[]]
        }
        
        dispatch(setCollectionPath([...parentSegments, collection]))
        navigateToLink('/folders/'+collection.id)
        dispatch(setCurrentGuide(null))
        if(viewerComponent) viewerComponent.scrollTop = 0

    }

}

export default useOpenCollection

