import { GigaUserApi, GigaUserApiClient } from "@giga-user-fern/api";
import { RichTextData } from "../components/formats/RichText/TextEditor";
import { cleanJSON, insertImageMetadata } from "../components/formats/RichText/utils/cleanImageSrc";
import { Guide, GuideData, GuideHeader, GuidePreview, Organization, VideoClip, Voice } from "../core/types/guide";
import { DocStep } from "../core/types/record";
import { setBranding, getBrandingColor } from '../core/utils/styleUtils';
import { showError } from '../core/utils/DOMUtils';
import { fetcher } from "./Adapter";
import { Collection, CollectionInput } from "../core/types/collections";
import { rootCollection } from "../types/files";
import {PlainDoc} from '../core/types/guide';
import logger from "../utils/logger";
import { FinishCreateGuideRequest, S3Metadata } from "@giga-user-fern/api/types/api/resources/guides";
// import { Id } from '../core/types/baseTypes';
const Id = GigaUserApi.Id;

const main_url = "http://localhost:8080"
// const main_url = "https://gigauser-backend.fly.dev"
// const main_url = "https://api.clueso.io"

type PresignedUrl = any;

function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    
    try {
        
        var byteString = atob(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    } catch (e) {
        logger.error("Error", e, dataURI);
        return dataURI;
    }
}

export const uploadToPresigned = async (body: any, presignedURL: PresignedUrl) => {
    const formData = new FormData();
    const data = { ...presignedURL };
    // data.fields.key = presignedURL.fields.key + "hello.txt"
    for (let key in data.fields) {
        formData.append(key, data.fields[key]);
    }

    formData.append("file", body);
    try {
        const resp = await fetch(data.url, { method: "POST", body: formData });
        logger.debug("UPLOADED", resp);
        if (!resp.ok) {
            // No upload
            logger.debug("BODY OF FAILED UPLOAD", body);
        }
        return resp;
    } catch (e) {
        logger.debug(e);
        return Promise.resolve(null);
    }
};


class HelpCenterSaver {
    queryAPI: GigaUserApiClient;
    organization: Organization | null;
    constructor() {
        this.queryAPI = new GigaUserApiClient({environment: main_url, fetcher: fetcher})
        this.organization = null
    }

    getBrandingColor = ()=>{
        return this.organization?.brandColor
    }

    initialiseFromHostname  = async() =>{

        this.queryAPI = new GigaUserApiClient({
            token: 'ignore', 
            environment: main_url, 
            fetcher: fetcher
        })

        const organization = await this.queryAPI.helpcenterQueries.getOrganization()

        if(organization.ok){
            this.organization = organization.body
            setBranding(this.organization.brandColor)
            this.queryAPI = new GigaUserApiClient({
                token: this.organization.id, 
                environment: main_url, 
                fetcher: fetcher
            })
            
        }
        return organization
        
    }

    fetchAllGuidePreviews = async (onlyPublished = true)=>{

        const allGuides = await this.queryAPI.helpcenterQueries.getAllGuidePreviews()
        
        if (allGuides.ok){
            const guidePreviews = allGuides.body
            logger.debug("guidePreviews: ", guidePreviews)
            
            return guidePreviews.filter(i => i.header.published===true)
            
        }
        else{
            return []
        }
    }


    fetchAllCollections = async ()=>{

        const allCollections = await this.queryAPI.helpcenterQueries.getAllCollections()
        
        if (allCollections.ok){
            const collections = allCollections.body

            return collections
            
        }
        else{
            return []
        }
    }

    fetchAllChildren = async(_parentId?: string, onlyPublished:boolean = true) =>{

        var parentId = undefined

        if(_parentId==="Collection_root"){
            parentId = undefined
        }
        else if(_parentId){
            parentId = GigaUserApi.Id(_parentId) 
        }

        const allChildren = await this.queryAPI.helpcenterQueries.getAllChildren({
            parentId: parentId
        })

        logger.debug("allChildren: ", allChildren)

        if(allChildren.ok){
            const {guides, collections} = allChildren.body
            if(onlyPublished){
                const _guides = guides.filter(g=>g.header.published)
                return{
                    guides: _guides, 
                    collections: collections
                }
            }
            else{
                return allChildren.body
            }
        }
        else{
            return {
                guides: [], 
                collections: []
            }
        }

    }

    getPath = async(id: string) =>{

        logger.debug("saver getPath: ", id)
        
        const res = await this.queryAPI.helpcenterQueries.getPath({
            id: GigaUserApi.Id(id)
        })

        if(res.ok){
            var path = res.body.collections
            const full_path = [rootCollection, ...path]

            logger.debug("got path: ", full_path)

            return full_path as [Collection, ...Collection[]]
        }

        else{
            return false
        }
    }

    

    validateToken = async (token: string)=>{
        return await this.queryAPI.helpcenterQueries.validateToken({tokenId: token})
    }

    search = async (text: string)=>{
        const searchResults = await this.queryAPI.helpcenterQueries.search({text: text})
        if(searchResults.ok){
            return searchResults.body
        }
        else{
            return []
        }
    }

    fetchGuideData = async (id: GigaUserApi.Id)=>{
        // logger.debug("CLIENT API", thi)

        const rootGuides = await this.queryAPI.helpcenterQueries.getGuideData({
            id: id
        })
        if (rootGuides.ok){
            const guideData = rootGuides.body
            return guideData
        }
        else{
            return null
        }

    }

    fetchGuidePreview = async(id: GigaUserApi.Id ) =>{
        
        const res = await this.queryAPI.helpcenterQueries.getGuidePreview({
            id: id
        })

        if(res.ok){
            const guidePreview = res.body
            return guidePreview
        }
        else{
            return null
        }

    }


}




class GuideSaver {

    mutationAPI: GigaUserApiClient;
    queryAPI: GigaUserApiClient;
    token: string;
    organization: Organization | null

    constructor() {
        this.queryAPI = new GigaUserApiClient({token : "random", environment: main_url})
        this.mutationAPI = new GigaUserApiClient({ token: "random", environment: main_url });
        this.token = "";
        this.organization = null
    }

    setCurrentCollection = () => {};
    getBrandingColor = ()=>{
        return this.organization?.brandColor
    }

    // setBrandingColor = (color:string)=>{
    //     // Used in Chrext to just maintain the same color
    //     if (!this.organization){
    //         this.organization = {id: Id('') ,domain:'no.com', brandColor: color, name: 'no'}
    //     }
    // }

    initialiseWithValues = (token: string, organization: Organization)=>{
        this.token = token;
        this.mutationAPI = new GigaUserApiClient({
            //auth header goes here.
            token: token,
            //Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
            //function to make sure that this token is valid.
            environment: main_url,
            fetcher: fetcher
        });
        this.organization = organization
        this.queryAPI = new GigaUserApiClient({
            token: organization.id,
            environment: main_url,
            fetcher: fetcher
        });
    }

    initialise = (accessToken: string) => {

        return new Promise(async(resolve, reject) =>{

            if (accessToken) {

                this.token = accessToken;
                this.mutationAPI = new GigaUserApiClient({
                    //auth header goes here.
                    token: accessToken,
                    //Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
                    //function to make sure that this token is valid.
                    environment: main_url,
                    fetcher: fetcher
                });
    
                const org = await this.mutationAPI.organization.getOrganization()
                logger.debug("got org: ", org)

                if(org.ok) {
                    this.organization = org.body
                    logger.debug("set org: ", this)
                    this.queryAPI = new GigaUserApiClient({
                        token: org.body.id,
                        environment: main_url,
                        fetcher: fetcher
                    });
                    logger.debug("branding being set", this.organization.brandColor)
                    // setBranding(this.organization.brandColor)
                    resolve(this.organization)
                    
                }
                else{
                    
                    reject(org)
                }
            }
        })
        
    };

    getLoomToken = async()=>{
        const token = await this.mutationAPI.guides.guideMutations.getLoomToken({id: "sdf"})
        if (token.ok){
            return token.body
        }
        else{
            return null
        }
    }

    saveAudio = async()=>{
        const url = await this.mutationAPI.audio.saveAudio()
        if (url.ok){
            return url.body
        }
        else{
            return null
        }
    }

    getTranscript = async(id: string, language?: string)=>{
        const transcript = await this.mutationAPI.audio.getTranscript({id: Id(id), language: language})
        if (transcript.ok){
            return transcript.body
        }
        else{
            return null
        }
    }

    initialiseFromOrgId = async(orgId: string) =>{

        logger.debug("initialiseFromOrgId: ", orgId)
        this.queryAPI = new GigaUserApiClient({
            token: orgId, 
            environment: main_url, 
            fetcher: fetcher
        })

        const organization = await this.queryAPI.organization.getOrganizationFromId({
            id: Id(orgId)
        })
        if(organization.ok){
            this.organization = organization.body
            setBranding(this.organization.brandColor)
            return this.organization
        }
        else{
            logger.error("failed", organization)
            return null
        }
    }

    initialiseFromHostname = async(hostname: string) =>{

        this.queryAPI = new GigaUserApiClient({
            token: 'ignore', 
            environment: main_url, 
            fetcher: fetcher
        })

        const organization = await this.queryAPI.organization.getOrganizationFromHostName({
            hostname: hostname
        })

        if(organization.ok){
            this.organization = organization.body
            setBranding(this.organization.brandColor)
            this.queryAPI = new GigaUserApiClient({
                token: this.organization.id, 
                environment: main_url, 
                fetcher: fetcher
            })
            return this.organization.id
        }
        else{
            logger.error("failed", organization)
            return null
        }
    }

    fetchAllGuidePreviews = async (onlyPublished = true)=>{

        const allGuides = await this.queryAPI.guides.guideQueries.getAllGuidePreviews()
        
        if (allGuides.ok){
            const guidePreviews = allGuides.body
            logger.debug("guidePreviews: ", guidePreviews)
            if(onlyPublished){
                return guidePreviews.filter(i => i.header.published==true)
            }
            else{
                return guidePreviews
            }
        }
        else{
            return []
        }
    }

    createEmptyTextGuide = async(parentId?: GigaUserApi.Id)=>{
        const resp = await this.mutationAPI.guides.guideMutations.createEmptyTextGuide({parentId: parentId === rootCollection.id ? undefined : parentId})
        
        if (resp.ok){
            

            return resp.body
            
        }
        else{
            return false
        }
    }

    fetchAllCollections = async ()=>{

        const allCollections = await this.queryAPI.collections.collectionQueries.getAllCollections()
        
        if (allCollections.ok){
            const collections = allCollections.body

            return collections
            
        }
        else{
            return []
        }
    }

    fetchAllChildren = async(_parentId?: string, onlyPublished:boolean = true) =>{

        var parentId = undefined

        if(_parentId=="Collection_root"){
            parentId = undefined
        }
        else if(_parentId){
            parentId = GigaUserApi.Id(_parentId) 
        }

        const allChildren = await this.queryAPI.collections.collectionQueries.getAllChildren({
            parentId: parentId
        })

        logger.debug("allChildren: ", allChildren)

        if(allChildren.ok){
            const {guides, collections} = allChildren.body
            if(onlyPublished){
                const _guides = guides.filter(g=>g.header.published)
                return{
                    guides: _guides, 
                    collections: collections
                }
            }
            else{
                return allChildren.body
            }
        }
        else{
            return {
                guides: [], 
                collections: []
            }
        }

    }

    getPath = async(id: string) =>{

        logger.debug("saver getPath: ", id)
        
        const res = await this.queryAPI.collections.collectionQueries.getPath({
            id: GigaUserApi.Id(id)
        })

        if(res.ok){
            var path = res.body.collections
            const full_path = [rootCollection, ...path]

            logger.debug("got path: ", full_path)

            return full_path as [Collection, ...Collection[]]
        }

        else{
            return false
        }
    }

    generateVideo = async (plainDoc: PlainDoc, guideID: GigaUserApi.Id, voice: Voice)=>{

        logger.debug("voice: ", voice)
        
        const enhancedDoc = await this.mutationAPI.guides.guideMutations.generateVideo({
            "doc": plainDoc, 
            "id": guideID, 
            "voice":voice
        })
        if (enhancedDoc.ok){
            return true
        }
        else{
            return false
        }
    }

    uploadVideoChunk = async (chunkNumber: number, guideId: GigaUserApi.Id, data: unknown)=>{
        const uploadChunkURL = await this.mutationAPI.guides.guideMutations.uploadVideoChunk({guideId: guideId, chunkNumber: chunkNumber})
        if (uploadChunkURL.ok){
            const result = await uploadToPresigned(data, uploadChunkURL.body)
            return result
        }
        return false
    }

    uploadImage = async ( guideId: GigaUserApi.Id, data: string)=>{
        const uploadChunkURL = await this.mutationAPI.guides.guideMutations.uploadImage(guideId)
        if (uploadChunkURL.ok){
            const result = await uploadToPresigned(dataURItoBlob(data), uploadChunkURL.body)
            if(result){
                return (uploadChunkURL.body as any).fields.key.split("/").at(-1);
            }
        }
        return false
    }

    compileVideo = async (totalChunks: number, guideId: GigaUserApi.Id)=>{
        const compiledVideo = await this.mutationAPI.guides.guideMutations.compileVideo({totalChunks: totalChunks,"guideId": guideId})
        if (compiledVideo.ok){
            return compiledVideo.body
        }
        else{
            return false
        }
    }
    generateEditedVideo = async(guideID: GigaUserApi.Id, clips: VideoClip[]) =>{
        const editedVideo = await this.mutationAPI.guides.guideMutations.generateEditedVideo({id: guideID, clips})
        if(editedVideo.ok) return true
        else return false
    }

    enhance = async (plainDoc: PlainDoc)=>{
        const enhancedDoc = await this.mutationAPI.guides.guideMutations.enhance({"doc": plainDoc})
        if (enhancedDoc.ok){
            return enhancedDoc.body.doc
        }
        else{
            return plainDoc
        }
    }

    enhanceVideoTranscript = async (plainDoc: PlainDoc)=>{
        const enhancedDoc = await this.mutationAPI.guides.guideMutations.enhanceVideoTranscript({"doc": plainDoc})
        if (enhancedDoc.ok){
            return enhancedDoc.body.doc
        }
        else{
            return plainDoc
        }
    }


    search = async (text: string)=>{
        const searchResults = await this.queryAPI.helpcenterQueries.search({text: text})
        if(searchResults.ok){
            return searchResults.body
        }
        else{
            return []
        }
    }

    fetchGuideData = async (id: GigaUserApi.Id)=>{
        // logger.debug("CLIENT API", thi)

        const rootGuides = await this.queryAPI.guides.guideQueries.getGuideData({
            id: id
        })
        if (rootGuides.ok){
            const guideData = rootGuides.body
            return guideData
        }
        else{
            return null
        }

    }

    fetchGuidePreview = async(id: GigaUserApi.Id ) =>{
        
        const res = await this.queryAPI.guides.guideQueries.getGuidePreview({
            id: id
        })

        if(res.ok){
            const guidePreview = res.body
            return guidePreview
        }
        else{
            return null
        }

    }

    deleteGuide = async (guideID: GigaUserApi.Id)=>{
        const resp = await this.mutationAPI.guides.guideMutations.deleteGuide({id: guideID})
        return resp.ok
    }

    deleteCollection = async(collectionID: GigaUserApi.Id) =>{
        const resp = await this.mutationAPI.collections.collectionMutations.deleteCollection({id: collectionID})
        return resp.ok
    }

    updateCollection = async(_collection: Collection) =>{
        /**
         * Used to update the Collection. NOT to be used for moving or reordering!
         * (description, name, published)
         */

        const collection = {..._collection}
        //Just making sure you're not updating parent Id or reordering
        delete collection["parentId"]

        const res = await this.mutationAPI.collections.collectionMutations.updateCollection(collection)

        if(res.ok){
            return res
        }
        else{
            return false
        }
        
    }

    updateGuidePreview = async(_guidePreview: GuidePreview) =>{
        /**
         * Used to update only the Guide Preview.
         * (description, name, published)
         * Do not use this to move or reorder guides. 
        */

        var guidePreview = {..._guidePreview}
        //Just making sure parentId and sequenceNumber does not get updated with this query. 
        delete guidePreview["parentId"]
        delete guidePreview["sequenceNumber"] 

        logger.debug("Saving guide preview; ", guidePreview)

        const res = await this.mutationAPI.guides.guideMutations.updateGuidePreview(guidePreview)
        
        if(res.ok){
            return res
        }

        return false
    }

    moveGuide = async(guidePreview: GuidePreview, newParentId: GigaUserApi.Id) =>{
        /**
         * Use this function to move a guide to a new collection
        */

        const res = await this.mutationAPI.guides.guideMutations.moveGuide({guidePreview: guidePreview, newParentId: newParentId})

        if(res.ok){
            return res.body
        }
        else{
            return false
        }
    }

    moveCollection = async(collection: Collection, newParentId: GigaUserApi.Id) =>{
        /**
         * Use this function to move a guide to a new collection
        */

        const res = await this.mutationAPI.collections.collectionMutations.moveCollection({collection: collection, newParentId: newParentId})

        if(res.ok){
            return res.body
        }
        else{
            return false
        }
    }



    reorderGuide = async(succeedingGuide: GuidePreview, precedingGuide?:GuidePreview) =>{
        /**
         * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
         */

        logger.debug("from saver: ",precedingGuide, succeedingGuide )

        const res = await this.mutationAPI.guides.guideMutations.reorderGuide({
            succeedingGuide: succeedingGuide, 
            precedingGuide: precedingGuide
        })

        logger.debug("res: ", res)

        if(res.ok) return res
        else return false

    }
    

    reorderCollection = async(succeedingCollection: Collection, precedingCollection?:Collection) =>{
        /**
         * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
         */


        const res = await this.mutationAPI.collections.collectionMutations.reorderCollection({
            succeedingCollection, 
            precedingCollection
        })

        logger.debug("res: ", res)

        if(res.ok) return res
        else return false

    }


    updateGuide = async (guide: Guide)=>{
        /**
         * Used to update the entire Guide (i.e: GuidePreview + GuideData)
         */
        const {id, guidePreview, guideData} = guide
        const richTextData : RichTextData = JSON.parse(JSON.stringify(guideData.plainDoc.data))
        const getCleanedMetadata = (metadata: S3Metadata)=>{
            const copy = JSON.parse(JSON.stringify(metadata)) as S3Metadata
            if (copy.generatedVideo){
                copy.generatedVideo.transcript.data = cleanJSON(copy.generatedVideo.transcript.data)
            }
            return copy
        }
        const sanitizedGuide: GuideData = {
            tourEnabled: guideData.tourEnabled,
            plainDoc: {version: "2023-03-12", data:cleanJSON(richTextData)},
            tour: guideData.tour,
            video: {
                metadata: getCleanedMetadata(guideData.video.metadata),
                src:  guideData.video.metadata?.recorderType === "loom" ? guideData.video.src : "",
            },
        };
        const updateData = await this.mutationAPI.guides.guideMutations.updateGuideData({
            id: id,
            guidePreview: guidePreview,
            guideData: sanitizedGuide
        })
        if (updateData.ok){
            const presignedURLs = updateData.body.presignedUrls;
            const promiseArray: Promise<Response | null>[] = [];

            const sanitisedWithID: any = insertImageMetadata(JSON.parse(JSON.stringify(guideData.plainDoc.data)), presignedURLs.images!, (src: string, index: number)=>{
                    logger.debug("UPLOADING DATA URI", src)    
                    const imageUpload = uploadToPresigned(
                            dataURItoBlob(src),
                            presignedURLs.images![index]
                        );
                        promiseArray.push(imageUpload);
                });
                
            const fullGuide: Guide = {
                id: Id(guide.id),
                guidePreview: guidePreview,
                guideData: {
                    tourEnabled: guideData.tourEnabled,
                    video: {
                        id: Id("ignored"),
                        metadata: getCleanedMetadata(guideData.video.metadata),
                        src:  guideData.video.metadata?.recorderType === "loom" ? guideData.video.src : "",
                    },
                    plainDoc: {
                        version: '2023-03-12',
                        data: sanitisedWithID
                    },
                    tour: guideData.tour,
                },
            };
            promiseArray.push(uploadToPresigned(
                JSON.stringify(fullGuide),
                presignedURLs.guide
            ))

            const updateGuideHeader = await this.mutationAPI.guides.guideMutations.updateGuidePreview(
                // {id: guide.id, guideData: sanitizedGuide ,guidePreview: guidePreview}
                guidePreview
            )
            const success = await Promise.all(promiseArray)
            if (!success.find(x=> x === null || x.ok === false)){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    initiateCreateGuide = async (parentID?: GigaUserApi.Id)=>{
        const id = await this.mutationAPI.guides.guideMutations.initiateCreateGuide({"parentId": parentID === rootCollection.id ? undefined: parentID})
        if (id.ok){
            return id.body
        }
        else{
            return null
        }
    }

    saveGuideAudio = async (guideId: GigaUserApi.Id)=>{
        const id = await this.mutationAPI.audio.saveGuideAudio(guideId)
        if (id.ok){
            return id.body as any
        }
        else{
            return null
        }
    }

    finishCreateGuide = async(request: FinishCreateGuideRequest)=>{
        const id = await this.mutationAPI.guides.guideMutations.finishCreateGuide(request)
        if (id.ok){
            return id.body
        }
        else{
            return null
        }
    }

    createGuide = async (_guidePreview: GuidePreview, guideData: GuideData) => {
        // Get number of images to upload
        // logger.debug("ATTEMPTING JSONIFICATION");
        // const newPlainDoc: GuideData["plainDoc"] = JSON.parse(
        //     JSON.stringify(guideData.plainDoc)
        // );

        var guidePreview = {..._guidePreview}

        logger.debug("createGuide guidePreview: ", _guidePreview)
        if(_guidePreview.parentId==rootCollection.id){
            guidePreview.parentId=undefined
        }

        const richTextData : RichTextData = JSON.parse(JSON.stringify(guideData.plainDoc.data))
        // logger.debug("ATTEMPTING JSONIFICATION", newPlainDoc);
        try {
            const sanitizedGuide: GuideData = {
                tourEnabled: guideData.tourEnabled,
                plainDoc: {
                    version: guideData.plainDoc.version,
                    data: cleanJSON(richTextData)
                },
                tour: guideData.tour,
                video: {
                    metadata: guideData.video.metadata,
                    src:  guideData.video.metadata?.recorderType === "loom" ? guideData.video.src : "",
                },
            };
            logger.debug("guide", sanitizedGuide);
            // const resp = await fetch('http://localhost:8080/guide_mutations/startCreateGuide', {})

            const resp =
                await this.mutationAPI.guides.guideMutations.startCreateGuide(
                    {id: Id('ignore'), guideData: sanitizedGuide, guidePreview: {
                        id: Id('ignore'), 
                        header: guidePreview.header, 
                        parentId: guidePreview.parentId
                    }}
                );

            logger.debug("RECEIVED", resp);

            if (resp?.ok) {
                const guideID = resp.body.id;
                const presignedURLs = resp.body.presignedUrls;

                const { guide, video, images } = presignedURLs;

                const promiseArray: Promise<Response | null>[] = [];
                if (video){
                    const videBlob =  await fetch(guideData.video.src).then(r => r.blob());
                    const videoFile = new File([videBlob], 'video.mp4', { type: 'video/mp4',    lastModified: Date.now() })
                    logger.debug("BLOB IS", videBlob)
                    const videoUpload = uploadToPresigned(
                        videoFile,
                        video
                    );
                    promiseArray.push(videoUpload);
                }
                
                // const sanitisedWithID: GuideData["plainDoc"] = richTextData;
                const sanitisedWithID: GuideData["plainDoc"] = insertImageMetadata(JSON.parse(JSON.stringify(guideData.plainDoc.data)), images!, (src: string, index: number)=>{
                        const imageUpload = uploadToPresigned(
                            dataURItoBlob(src),
                            images![index]
                        );
                        promiseArray.push(imageUpload);
                } );

                const fullGuide: Guide = {
                    id: Id(guideID),
                    guidePreview: {
                        id: Id(guideID),
                        header: guidePreview.header, 
                        parentId: guidePreview.parentId
                    },
                    guideData: {
                        tourEnabled: guideData.tourEnabled,
                        video: {
                            id: Id("ignored"),
                            metadata: guideData.video.metadata,
                            src:  guideData.video.metadata?.recorderType === "loom" ? guideData.video.src : "",
                        },
                        plainDoc: {
                            version: guideData.plainDoc.version,
                            data: sanitisedWithID},
                        tour: guideData.tour,
                    },
                };
                const jsonUpload = uploadToPresigned(
                    JSON.stringify(fullGuide),
                    guide
                );
                promiseArray.push(jsonUpload);
                logger.debug("PROMISES ARE", promiseArray);
                const success = await Promise.all(promiseArray);
                if (!success.find(x=> x === null || x.ok === false)){
                    const r =
                    await this.mutationAPI.guides.guideMutations.createGuide(
                        fullGuide
                    );
                    if (r.ok){
                        logger.debug("create guide: ", r)
                        return guideID
                    }
                    else{
                        return false
                    }
                }
                else{
                    showError("Uploading some asset failed! Please rerecord")
                    return false
                }
                
            }
        } catch (e) {
            logger.error("OOPS", e);
            return false;
        }
        return false
    };

    createCollection = async(collection: CollectionInput) =>{

        try{
            logger.debug("collectioninput: ", collection)
            const resp = await this.mutationAPI.collections.collectionMutations.createCollection(collection)
            if(resp?.ok){
                logger.debug("created collection: ", resp.body)
                return resp.body
            }
            else{
                logger.error("could not create collection: ", resp)
                return false
            }
        }

        catch(e){
            logger.error("could not create collection: ", e)
            return false
        }

    }
}
export const helpSaver = new HelpCenterSaver()

export const saver = new GuideSaver();
