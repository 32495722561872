import React from 'react';
import logo from '../../assets/svgs/logo'
import messageIcon from "../../assets/svgs/messageIcon"
import helpCircleIcon from '../../assets/svgs/helpCircleIcon';

type FooterProps = {
    supportFunction?: ()=>void

};

import './Footer.css'
import Icon from '../../ui/Icon/Icon';
import { getBrandingColor } from '../../core/utils/styleUtils';
import { propIsFunction } from '@remirror/react';

const Footer:React.FC<FooterProps> = (props) => {

    var brandColor = getBrandingColor()
    
    return (
        <div className="gigauser-help-footer">

            {
                props.supportFunction?
                <div className='gigauser-footer-buttons'>

                    <div className='gigauser-footer-tabs'>
                        <FooterTab onClick={() =>{}} active text='Help' icon={helpCircleIcon(true?brandColor:"black")} />

                        <FooterTab text='Chat' icon={messageIcon()} onClick ={props.supportFunction}/>

                    </div>
                    
                </div>
                :null

            }

            
            <div className='gigauser-clueso-footer'>

            <span>Powered by</span>
            <div className="gigauser-footer-logo"  onClick = {() =>{
                window.open("https://clueso.io", '_blank');
            }} >{logo}</div>

            </div>
            

        </div>
    )
}



type FooterTabProps = {
    text: string, 
    icon: React.ReactNode, 
    active?:boolean, 
    onClick: () => void
};

const FooterTab:React.FC<FooterTabProps> = (props) => {
    
    return (
        <div className={`gigauser-footer-tab ${props.active?"gigauser-active-footer":"gigauser-inactive-footer"}`}
            onClick={props.onClick}
        >

            <Icon className='gigauser-footer-tab-icon'>{props.icon}</Icon>

            <div className='gigauser-footer-tab-text'>{props.text}</div>

        </div>
    )
}

export default Footer;