// The aim of this file is to create message boxes
// around bounding boxes.
// They should auto-reposition based on the element being clicked.
import {
    computePosition,
    autoPlacement,
    offset,
    flip,
    arrow,
    shift
} from "@floating-ui/dom";
import "./messages.css";
import { TourStep } from "../../types/record";
import logger from "../../../utils/logger";
export const createNextMessage = (
    step: TourStep,
    stepIndex: number,
    target: HTMLElement,
    blockingContainer: HTMLElement,
    options: {
        nextButton: boolean;
    }
) => {
    const popupDiv = document.createElement("div");
    popupDiv.className = "gigauser-popup-tooltip";
    popupDiv.style.display = "block";
    // Add header
    const header = document.createElement("div");
    header.className = "gigauser-popup-header";
    const title = document.createElement("div");
    title.className = "gigauser-popup-title";
    title.innerText = step.metadata.title ?? `Step ${stepIndex + 1}`;
    header.appendChild(title);

    // const close = document.createElement("div")
    // close.className = "gigauser-popup-close"
    // close.innerHTML = `
    // <svg style="width:100%;" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .528 7.472 0 4 3.472.528 0 0 .528 3.472 4 0 7.472.528 8 4 4.528 7.472 8 8 7.472 4.528 4 8 .528Z" fill="#000"/></svg>
    // `
    const closeContainer = document.createElement('div')
    closeContainer.className = "gigauser-popup-close";
    const close = document.createElement("img");
    close.className = "gigauser-popup-close";
    close.src = "data:image/svg+xml,%3Csvg viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .528 7.472 0 4 3.472.528 0 0 .528 3.472 4 0 7.472.528 8 4 4.528 7.472 8 8 7.472 4.528 4 8 .528Z' fill='%23000'/%3E%3C/svg%3E";
    closeContainer.append(close)
    header.appendChild(closeContainer);
    popupDiv.appendChild(header);

    const popupBody = document.createElement("div");
    popupBody.className = "gigauser-popup-body";
    const paragraph = document.createElement("p");
    paragraph.textContent = step.metadata.text;
    popupBody.appendChild(paragraph);
    popupDiv.appendChild(popupBody);
    let nextButton;
    if (options.nextButton) {
        const popupFooter = document.createElement("div");
        popupFooter.className = "gigauser-popup-footer";
        nextButton = document.createElement("button");
        nextButton.className = "gigauser-popup-next";
        nextButton.textContent = "Next";
        popupFooter.appendChild(nextButton);
        popupDiv.appendChild(popupFooter);
    }

    const arrowDiv = document.createElement("div");
    arrowDiv.className = "gigauser-popup-arrow";
    arrowDiv.innerHTML = `<svg viewBox="0 0 500 500" width="100%" style="width:100%;fill:white;" xmlns="http://www.w3.org/2000/svg">
    <line style="fill:none;" x1="0" x2="500" y1 ="0" y2="250" stroke="black" strokeWidth="5%"/>
<line style="fill:none;" x1="0" x2="500" y1 ="500" y2="250" stroke="black" strokeWidth="5%"/>
<polygon points= "0,0 500,250 0,500"/>
</svg>`

    // const arrowImg = document.createElement("img");
    // arrowImg.src = chrome.runtime.getURL("/arrow.svg");
    // arrowImg.style.width = "16px"
    // arrowDiv.appendChild(arrowImg);

    popupDiv.appendChild(arrowDiv);
   
    blockingContainer.appendChild(popupDiv);
    computePosition(target, popupDiv, {
        placement: "right",
        middleware: [
            shift(),
            flip({fallbackAxisSideDirection: 'end'}),
            offset(16),
            arrow({
                element: arrowDiv,
            }),
        ],
    }).then(({ x, y, placement, middlewareData }) => {
        if (middlewareData.arrow) {
            const { x, y } = middlewareData.arrow;
        
      
            //   if (middlewareData.arrow) {
            //     Object.assign(arrowEl.style, {
            //       [staticSide]: `${-arrowEl.offsetWidth}px`
            //     });
            //   }
            if (placement === "right") {
                // Flip arrow
                // arrowDiv.style.transform = "rotate(180deg)";
                Object.assign(arrowDiv.style, {
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    left: "-16px",
                    top: y != null ? `${y}px` : "",
                    transform: "rotate(180deg)",
                });
            }
            else if(placement==="bottom"){
                Object.assign(arrowDiv.style, {
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    top: "-16px",
                    left: x != null ? `${x}px` : "",
                    transform: "rotate(270deg)",
                });
            }
            else if(placement==="top"){
                Object.assign(arrowDiv.style, {
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    bottom: "-16px",
                    left: x != null ? `${x}px` : "",
                    transform: "rotate(90deg)",
                });
            }
            else{
                Object.assign(arrowDiv.style, {
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    right: "-16px",
                    top: y != null ? `${y}px` : "",
                });
            }
            
        }
        Object.assign(popupDiv.style, {
            left: `${x}px`,
            top: `${y}px`,
            display: "block",
            position: "absolute",
            width: "250px",
            boxShadow:
                "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195)",
            backgroundColor: "white",
            mixBlendMode: "normal",
            borderRadius: "10px",
            color: "black",
            pointerEvents: "auto"
             // ENSURE THIS IS ALWAYS CLICKABLE 
            
        });
        if (!options.nextButton) {
            popupBody.style.marginBottom = "30px";
        }
        
        logger.debug("PLACEMENT IS", placement);
    });
    return {
        next: nextButton,
        end: close,
        controls: popupDiv,
    };
};
