import React, { useEffect, useRef, useState } from "react";
import "./RichTextToolbar.css";
import magicwandIcon from "../../../../../assets/svgs/magicwandIcon";
import Icon from "../../../../../ui/Icon/Icon";
import undoIcon from "../../../../../assets/svgs/undoIcon";
import redoIcon from "../../../../../assets/svgs/redoIcon";
import { useActive, useChainedCommands, useCommands, useRemirrorContext } from "@remirror/react";
import { PlainDoc } from "@giga-user-fern/api/types/api/resources/guides";
import { cleanJSON, refillImages } from "../../utils/cleanImageSrc";
import LoadingRing from "../../../../../assets/gifs/LoadingRing/LoadingRing";
import { RemirrorJSON } from "remirror";
import logger from "../../../../../utils/logger";
import { TextEditorType } from "../../TextEditor";
import voiceIcon from "../../../../../assets/svgs/voiceIcon";

import { getFrontendVoice, googleVoices } from "../../../../../utils/voiceUtils";
import { Voice } from "../../../../../core/types/guide";
import BlockTypeDropdown from "./BlockTypeDropdown/BlockTypeDropdown";
import ListOptions from './ListOptions/ListOptions';
import BlockquoteButton from "./BlockquoteButton/BlockquoteButton";
import HRButton from "./HRButton/HRButton";
import TextStyleOptions from './TextStyleOptions/TextStyleOptions';

type RichTextToolbarProps = {
    onEnhance?: (x: PlainDoc) => Promise<PlainDoc>;
    onGenerate?: () => void;
    save?: (x: RemirrorJSON) => void;
    version: string;
    scrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
    fixToolbar?: boolean;
    textEditorType: TextEditorType;
};

export type VoiceProps = {
    voice: Voice;
    onClickVoice: () => void;
};

const RichTextToolbar: React.FC<RichTextToolbarProps & (VoiceProps | {})> = (props) => {
    const mediumGrey = "#4b4b4b";

    const { undo } = useCommands();
    const { redo } = useCommands();
    const { setContent, manager } = useRemirrorContext();
    const [enhanceLoading, setEnhanceLoading] = useState(false);

    // const dispatch = useAppDispatch()

    const toolbarElementRef = useRef<HTMLDivElement | null>(null);
    const [maintenanceWidth, setMaintenanceWidth] = useState<number>(350);

    const [isTop, setIsTop] = useState(false);

    if ("voice" in props) {
        var displayVoice = getFrontendVoice(props.voice);
    }
    const { commands } = useChainedCommands() as any;
    const active = useActive();
    // const list = props.items;
    const nonChain = useCommands();

    useEffect(() => {
        // if (!isTop) return;

        const { scrollContainerRef } = props;

        if (!scrollContainerRef?.current) return;

        if (!props.fixToolbar) scrollContainerRef.current.addEventListener("scroll", isSticky);

        if (toolbarElementRef) {
            logger.debug("my toolbar: ", toolbarElementRef.current);
            logger.debug(" my width: ", toolbarElementRef.current?.offsetWidth);
            if (toolbarElementRef.current) {
                var width = toolbarElementRef.current?.offsetWidth;
                if (!props.fixToolbar) width -= 12;

                if (width) setMaintenanceWidth(width);
                setIsTop(true)
            }
        }

        return () => {
            if (!scrollContainerRef?.current) return;
            scrollContainerRef.current.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = (e: any) => {
        const { scrollContainerRef } = props;

        const scrollContainer = scrollContainerRef?.current;
        const toolbarElement = toolbarElementRef.current;

        if (!scrollContainer || !toolbarElement) {
            console.warn("couldnt get the refs: ", toolbarElement, scrollContainer);
            return;
        }

        const scrollY = scrollContainer.scrollTop;
        const pos = toolbarElement.getBoundingClientRect().top;

        // logger.debug("toolbar: ", pos, "scroll: ", scrollY)

        // if (scrollY >= pos - 10) {
        //     // logger.debug("isTop true!")
        //     setIsTop(true);
        // } else {
        //     // logger.debug("isTop false!")
        //     setIsTop(false);
        // }
    };

    const enhanceDocument = async () => {
        if (enhanceLoading) return;

        logger.debug("enhancing....");

        setEnhanceLoading(true);

        const jsonDoc = cleanJSON(JSON.stringify(manager.view.state.doc.toJSON()));
        const oldCopy = JSON.parse(JSON.stringify(manager.view.state.doc.toJSON()));
        let newjsonDOc = await props.onEnhance?.({
            version: props.version ?? "2023-03-12",
            data: jsonDoc,
        });
        logger.debug("RECEIVED", newjsonDOc);
        setContent(refillImages(oldCopy, JSON.parse(newjsonDOc?.data as string) as RemirrorJSON));
        props.save?.(refillImages(oldCopy, JSON.parse(newjsonDOc?.data as string) as RemirrorJSON));
        logger.debug("enhancing done");

        setEnhanceLoading(false);
    };

    return (
        <div
            id="gigauser-RichTextToolbar"
            ref={toolbarElementRef}
            className={`gigauser-RichTextToolbar ${props.fixToolbar ? "gigauser-fix-toolbar" : ""} ${
                isTop ? "gigauser-isTop" : "gigauser-notIsTop"
            }`}
            style={isTop || props.fixToolbar ? { width: maintenanceWidth } : undefined}
        >
            <div className="gigauser-toolbar-special-buttons">
                <div className="gigauser-special-button gigauser-enhance-button" onClick={enhanceDocument}>
                    <Icon className="gigauser-special-button-icon ">{magicwandIcon("#d89f03")}</Icon>
                    <div className="gigauser-special-button-text">AI Enhance</div>

                    <div className="gigauser-enhanceLoadingRing">
                        {enhanceLoading ? <LoadingRing color="#d89f03" /> : null}
                    </div>
                </div>
                {props.textEditorType === "platform" && <BlockTypeDropdown></BlockTypeDropdown>}
                {props.textEditorType === "platform" && <TextStyleOptions></TextStyleOptions>}

                {props.textEditorType === "platform" && <BlockquoteButton></BlockquoteButton>}
                {props.textEditorType === "platform" && <HRButton></HRButton>}

            </div>

            <div className="gigauser-toolbar-regular-buttons">
                {"voice" in props ? (
                    <div className="voiceIcon-container" onClick={props.onClickVoice}>
                        <Icon className="gigauser-RichTextToolbar-voice-icon">{voiceIcon(mediumGrey)}</Icon>

                        <span className="gigauser-voice-name">{displayVoice?.displayName}</span>
                    </div>
                ) : null}
                <Icon className="gigauser-RichTextToolbar-reg-icon" hoverTip="Undo" onClick={undo}>
                    {undoIcon(mediumGrey)}
                </Icon>

                <Icon className="gigauser-RichTextToolbar-reg-icon" hoverTip="Redo" onClick={redo}>
                    {redoIcon(mediumGrey)}
                </Icon>
            </div>
        </div>
    );
};
export default RichTextToolbar;
