import React, {useContext, useState} from 'react';
import { Folder, Item, rootCollection } from '@gigauser/common/src/types/files';
import { Collection } from '@gigauser/common/src/core/types/collections';
import homeIcon from '../../../assets/svgs/home';
import Icon from '../../Icon/Icon';
import { getBrandingColor } from '../../../core/utils/styleUtils';



type BreadcrumbSectionProps = {
    segment: Collection, 
    navigationHandler: (segment: Collection) => void | null, 
    activeSegment?:boolean, //if its the last section, 
    appTheme?:"gigauser-light" | "gigauser-dark"
};

const BreadcrumbSection:React.FC<BreadcrumbSectionProps> = (props) => {

    const brandColor = getBrandingColor(props.appTheme)
    const defaultHomeColor = props.activeSegment?brandColor:"#c2c1c1"

    const [homeColor, setHomeColor] = useState(defaultHomeColor)

    const onHomeHover = () =>{
        // setHomeColor(brandColor)
    }

    const onHomeLeave = () =>{
        setHomeColor(defaultHomeColor)
    }

    return(

    <div className={`gigauser-PageTitle-pathSegment ${props.activeSegment?"gigauser-activeSegment":""} ${props.segment.id === rootCollection.id?"gigauser-home-segment":"gigauser-text-segment"}`} onClick={() => props.navigationHandler(props.segment)}
    onMouseEnter={onHomeHover} onMouseLeave={onHomeLeave}
    >
        {props.segment.id === rootCollection.id ?
            <div className='gigauser-breadcrumbs-home' onMouseEnter={onHomeHover} onMouseLeave={onHomeLeave}>
                {homeIcon(props.activeSegment?brandColor:"#c2c1c1")}
            </div>

            : props.segment.name}
    </div>
    )
}
export default BreadcrumbSection;