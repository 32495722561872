import * as React from "react";
import { TourStep } from "../../../core/types/record";
import { startTour , GuidedTour } from "../../../core/tour/tour";
import useEffect from 'react';
import "./TourViewer.css"
import { saver } from "../../../network/saver";
export interface TourViewerProps {
    tour: TourStep[];
	onClose: ()=>void
}

export default function TourViewer(props: TourViewerProps) {
	const [loading, setLoading] = React.useState<boolean>(true)
	const [error, setError] = React.useState<boolean>(false)
	React.useEffect(()=>{
		const tour = new GuidedTour(props.tour, {
			allowAllClicks: false,
			editor: false,
		});
		tour.canStart().then((bool)=>{
			if (bool){
				setError(false)
			}
			else{
				setError(true)
			}
			setLoading(false)
		})
	},[])
	if (loading){
		return <div>
			
			<center>
			<div className="gigauser-lds-ring-tour"><div style = {{borderColor: `${saver.getBrandingColor()} transparent transparent transparent`}}></div><div></div><div></div><div></div></div>
			</center>
		</div>
	}
	else if (error){
		return <div>
			Oops! Looks like there's an issue with this tour. Please try watching the video or reading the guide instead.
		</div>
	}
	else{
		return <>
		<br></br>
		<center><button 
		className="gigauser-start-tour"
		onClick={()=>{
			setTimeout(props.onClose, 0)
			startTour(props.tour)
		}}>
				Start Tour
			</button></center>
		<br></br>
		<center>
		You can end the tour by clicking 'End Tour' at the top.	
		</center>
		</>
	}
    
}
