import { retry } from "@lifeomic/attempt";
import { TourStep, DOMElementData } from "../types/record";
import { GigaUserApi } from '@giga-user-fern/api';

const compareElementInTree = (step: TourStep, element: HTMLElement) => {
    // Idea: Get potential matches based on tag name
    // Now, compare the text content and class name and move up
    if (getComputedStyle(element).display === "none" || element.offsetParent === null) return false
    const elementMatch =
        element.textContent === step.domElementData.textContent &&
        element.textContent.length > 0 &&
        element.getAttribute("class") === step.domElementData.className;
    if (elementMatch) return true;
    else {
        if (
            (element.textContent === step.domElementData.textContent || 
                element.getAttribute("class") === step.domElementData.className) &&
            step.domElementData.textContent.length === 0
        ) {
            // There may be multiple matching till here
            // We need to compare parents to the step's parents
            let tester = element.parentElement;
            let upSteps = step.metadata.parents.length;
            let start = 0;
            while (start < upSteps && tester) {
                if (
                    tester.textContent ===
                        step.metadata.parents[start].textContent &&
                    tester.textContent.length !== 0
                ) {
                    return true;
                } else if (tester.textContent?.length === 0) {
                    start += 1
                    tester = tester.parentElement
                } else{
                    return false
                }
            }
            
        }
    }
    // Element classes itself don't align or text doesn't match
    // For now, return false
    return false;
};

export const findElement = async (step: TourStep) => {
    return await retry(
        async () => {
            const prom = new Promise(
                (resolve: (x: HTMLElement) => void, reject: () => void) => {
                    // Fetch the right elements
                    if (step.metadata.customSelector){
                        const element = document.querySelector(`[${step.metadata.customSelector.name}=${step.metadata.customSelector.value}]`) as HTMLElement
                        if (element){
                            resolve(getRequiredAncestor(
                                step.metadata.ancestor,
                                element
                            ))
                        }
                        else{
                            reject()
                        }
                    }
                    // Try picking by ID

                    if (step.domElementData?.id) {
                        const found = document.getElementById(
                            step.domElementData.id
                        );
                        if (found) {
                            resolve(
                                getRequiredAncestor(
                                    step.metadata.ancestor,
                                    found
                                )
                            );
                        }
                    }

                    const found = document.getElementsByTagName(
                        step.domElementData.tagName
                    ) as HTMLCollectionOf<HTMLElement>;
                    const element = Array.from(found).find((x)=>compareElementInTree(step, x))
                    if (element){
                    
                        resolve(
                            getRequiredAncestor(
                                step.metadata.ancestor,
                                element
                            )
                        );
                    }
                    reject();
                }
            );
            return prom;
        },
        { delay: 100, maxAttempts: 30 }
    );
    // TOTAL WINDOW OF TIME: 3 seconds, 100ms delay
};

const getRequiredAncestor = (ancestor: number, target: HTMLElement) => {
    let number = ancestor;
    let final = target;
    while (number) {
        number = number - 1;
        final = final.parentElement as HTMLElement;
    }
    return final;
};

export const getDomElementDataFromTarget = (target: HTMLElement) => {
    // If input, we might want to go up till we hit the div with label
    // Works for at least Pulley

    const item: TourStep["domElementData"] = {
        id: (typeof target.id === 'string') ? target.id : '',
        textContent: target.textContent ?? "",
        className: target.getAttribute("class") ?? "",
        tagName: target.tagName,
    };
    return item;
};

export const getTextFromTarget = (target: HTMLElement) => {
    let text = "Click here";
    if (target.tagName === "DIV" || target.tagName === "BUTTON") {
        text = "Click on '" + target.textContent + "'";
    }
    if (target.tagName === "INPUT" || target.tagName === "CONTENTEDITABLE") {
        text = "Type in this field";
    }
    return text;
};

const findParentsArray = (target: HTMLElement) => {
    const x: DOMElementData[] = [];
    let parent: HTMLElement | null = target;
    let totParents = 7;
    while (parent && totParents) {
        parent = parent.parentElement;
        if (parent) {
            x.push(getDomElementDataFromTarget(parent));
        }
        totParents -= 1;
    }
    return x;
};

export const getTourStep = (target: HTMLElement, stepId: GigaUserApi.Id) => {
    const url = getURLSpecificToCompany();
    const x: TourStep = {
        stepId: stepId,
        domElementData: getDomElementDataFromTarget(target),
        metadata: {
            text: getTextFromTarget(target),
            ancestor: 0,
            skippable: false,
            autoProgressable: target.tagName === "INPUT" ? "newButton" : "auto",
            parents: findParentsArray(target),
        },
    };

    return x;
};

const getURLSpecificToCompany = () => {};
