import React, { useContext, useEffect, useState } from 'react';
import HelpCenterBrowserTopbar from '../HelpCenterBrowserTopbar/HelpCenterBrowserTopbar';
import './HelpCenterBrowser.css'

import HelpCenterViewer from '../HelpCenterViewer/HelpCenterViewer';
import { HelpCenterProps, Navigate } from '@gigauser/common/src/help-center/HelpCenter/HelpCenter';
import { getPathFromRoute, isProdDomain } from '@gigauser/common/src/help-center/utils/routeUtils';
import logger from '@gigauser/common/src/utils/logger';
import { Guide, GuidePreview } from '@giga-user-fern/api/types/api/resources/guides';
import { Collection } from '@gigauser/common/src/core/types/collections';
import { rootCollection } from '@gigauser/common/src/types/files';
import { boolean } from '@giga-user-fern/api/types/core/schemas';
import ExtHelpCenterContext from '../../ExtHelpCenterContext';
import { captureEvent } from '@gigauser/common/src/core/analytics/analytics';
import HelpCenterIndex from '../../HelpCenterIndex/HelpCenterIndex';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { setCollectionPath, setCurrentGuide } from '../../../../redux/slices/hcSlice';
import useOpenGuide from '../../../../hooks/useOpenGuide';

type Page = "Guide" | "Collection"

type HelpCenterBrowserProps = {

}

const HelpCenterBrowser:React.FC<HelpCenterBrowserProps> = (props) => {

    var navigate:Navigate|undefined

    const helpCenterContext = useContext(ExtHelpCenterContext)

    if(helpCenterContext.navigator){
        navigate = helpCenterContext.navigator()
    }
    

    const [loading, setLoading] = React.useState(true)

    const dispatch = useAppDispatch()

    const collectionPath =useAppSelector(state => state.hc.collectionPath)

    const onClickCollectionBreadcrumbs = async(collection: Collection) =>{

        const newSegments: Collection[]  = [];
        dispatch(setCurrentGuide(null))

        if(!collectionPath) return
  
        for (let i = 0; i < collectionPath.length; i++) {

            logger.debug("check: ", collectionPath[i].id, collection.id)

            if (collectionPath[i].id === collection.id) {

                newSegments.push(collectionPath[i]);
                // previousID.current = currentID
                dispatch(setCollectionPath(newSegments as [Collection, ...Collection[]]))

                navigateToLink('/folders/'+collection.id)

                return;
            }
            
            newSegments.push(collectionPath[i]);
        }
        
    }

    const navigateToLink = (string:string) =>{


        if(navigate){
            logger.debug("time to navigate to link: ", string, process.env)
            const newURL = string + (!isProdDomain() ?`?org=${helpCenterContext.getOrganization()?.id}` : '')
            logger.debug("newURL=", newURL)
            navigate(newURL)
        }
    }

    const popStateFunction = async () => {

        setLoading(true)
        
        const result = await getPathFromRoute({
            getPath: helpCenterContext.getPath, 
            fetchGuidePreview: helpCenterContext.fetchGuidePreview,
            onLoadingGuide: () =>{},
            onLoadingCollection: ()=>{
                dispatch(setCurrentGuide(null))
            }
        });

        setLoading(false)

        logger.debug("popstate result: ", result)

        if(!result){
            navigateToLink("/folders/" + rootCollection.id)
            throw new Error("Unexpected url!!")
        }

        else{

            if(result.type==="folders"){
                logger.debug("popstate folders newPath: ", result.newPath)
                dispatch(setCurrentGuide(null))

                
            }
            else{
                const preview = result.guidePreview
                const data = await helpCenterContext.fetchGuideData(preview.id)
                // setSearchString("")

                dispatch(setCurrentGuide({
                    id: preview.id,
                    guidePreview: preview,
                    guideData: data!
                }))

                

            }

            dispatch(setCollectionPath(result.newPath))


        }

        // const newPath = [rootCollection]
        // store.dispatch(updateCollectionPath(newPath as [Collection, ...Collection[]]));
    }
    
    useEffect(() =>{

        //-------- Check what to initialise as collectionPath ----------

        popStateFunction()

        // ------- Handle browser forward and back operations ----------

        window.addEventListener("popstate", popStateFunction)

        // 👇️ remove the event listener when the component unmounts
        return () => {
            logger.debug("removing contentnavigator popstate")
            window.removeEventListener('popstate', popStateFunction);
        };

    }, [])

    const organization = helpCenterContext.getOrganization? helpCenterContext.getOrganization():null

    return (
        <div className="HelpCenterBrowser">

            <HelpCenterBrowserTopbar />

            <div className="HelpCenterBrowser-row2">
                <HelpCenterIndex />

        
                <HelpCenterViewer 
                    loading={loading}
                    onClickCollectionBreadcrumbs  = {onClickCollectionBreadcrumbs}
                />
                    
                

            </div>

        </div>
    )
}
export default HelpCenterBrowser;