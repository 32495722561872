import React, { useContext, useEffect, useState } from 'react';
import './HelpCenterIndex.css'
import ExtHelpCenterContext from '../ExtHelpCenterContext';
import { GuidePreview } from '@gigauser/common/src/core/types/guide';
import { Collection } from '@gigauser/common/src/core/types/collections';
import { CollectionNode, Tree, setTree, updateNodeInTree } from '../../../redux/slices/hcSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/index';
import FolderAccordion from './FolderAccordion/FolderAccordion';
import useGetSubtree from './hooks/useGetSubtree';
import { rootCollection } from '@gigauser/common/src/types/files';
import useWindowSize from '@gigauser/common/src/hooks/useWindowSize';
import { indexbarThreshold } from '../layout';

type HelpCenterIndexProps = {
    
};

const HelpCenterIndex:React.FC<HelpCenterIndexProps> = () => {

    const [initLoading, setInitLoading] = useState(true)


    const dispatch = useAppDispatch()
    const tree = useAppSelector(state => state.hc.tree)

    const getSubtree = useGetSubtree()

    const collectionPath = useAppSelector(state => state.hc.collectionPath)

    const initTree = async () => {

        setInitLoading(true)
        
        const treeCopy = await getSubtree()

        console.log("Tree is : ", treeCopy)
        dispatch(setTree(treeCopy))

        //Expand the primary nodes. 
        
        return

        const primaryCollections: CollectionNode[] = treeCopy.filter(node=> node.type==="collection") as CollectionNode[]

        for (let primaryCollection of primaryCollections){

            var nodeCopy = {...primaryCollection}
            const subtree = await getSubtree(nodeCopy.collection.id.toString())
            if(subtree.length){
                nodeCopy.collapsed = false
                nodeCopy.children = subtree
    
                dispatch(updateNodeInTree(nodeCopy))
    
            }

        }

        setInitLoading(false)

    }

    useEffect(() => {
        initTree()
    }, [])


    const expandTree = async() => {
        
        var currentSubtree: CollectionNode[] = []
        currentSubtree = tree.filter(node => node.type==="collection") as CollectionNode[]

        const _collectionPath = [...collectionPath].filter(collection => collection.id!==rootCollection.id)

        for (const collection of _collectionPath) {
            //Let's loop through the collectionpath and make sure all are expanded. 

            console.log("------------At collection-------------- ", collection)
            console.log("currentSubtree is: ", currentSubtree)

            for (let node of currentSubtree){
                
                if(node.collection.id === collection.id){
                    //This node is a part of the collection path. Let's make sure it's expanded
                    
                    console.log("Examining the node: ", node)

                    if(Array.isArray(node.children) && node.collapsed){
                        //This node is already loaded. Let's just expand it 

                        console.log("Node is already expanded: ", node.children)

                        let nodeCopy = {...node}
                        nodeCopy.collapsed = false
                        dispatch(updateNodeInTree(nodeCopy))

                        currentSubtree = node.children.filter(node => node.type==="collection") as CollectionNode[]
                    }
                    else if(!Array.isArray(node.children)){
                        //This node is not loaded. Let's load and expand it
                        console.log("This node is to be expanded: ", node)
                        let nodeCopy = {...node}
                        const subTree = await getSubtree(nodeCopy.collection.id.toString())
                        nodeCopy.children = subTree
                        nodeCopy.collapsed = false
                        dispatch(updateNodeInTree(nodeCopy))

                        currentSubtree = subTree.filter(node=> node.type==="collection") as CollectionNode[]
                        console.log("resetting subtree: ", currentSubtree)

                    }
                    else{
                        //This node is already loaded and expanded. Do nothing. 
                    }
                }
            }

        }
    }

    useEffect(() => {
        console.log("changed : ", collectionPath, tree)
        expandTree()

    }, [collectionPath, tree])

    const windowSize = useWindowSize()
    
    return (
        <div className={`HelpCenterIndex style-scroll-square ${windowSize.width<indexbarThreshold?"hide":""}`}>
            
            <div className='mainFolderAccordion'>
            <FolderAccordion tree={tree} parentSegments={[]}></FolderAccordion>

            </div>

        </div>
    )
}



export default HelpCenterIndex;