import React, { useEffect, useRef, useState } from "react";

// import trash from '../../assets/icons/trash.svg';

import "./Viewer.css";
import HorizontalTabs from "../../../ui/tabs/HorizontalTabs/HorizontalTabs";
// import PlainDoc from "../../formats/PlainDoc/PlainDoc";
import Video from "../../formats/Video/Video";

import Icon from "../../../ui/Icon/Icon";
import "../../../styles/animations/shake.css";
import { Guide } from "@gigauser/common/src/core/types/guide";

import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import { TextEditor } from "../../formats/RichText/TextEditor";

import closeIcon from "../../../assets/svgs/closeIcon";
import TourViewer from "../../formats/TourViewer/TourViewer";
import { Device } from "../../../types/devices";
import useWindowSize from "../../../hooks/useWindowSize";
import './Viewer.css'
import { getBrandingColor } from "../../../core/utils/styleUtils";
import readIcon from "../../../assets/svgs/readIcon";
import watchIcon from "../../../assets/svgs/watchIcon";
import SimpleTabs from "../../../ui/tabs/SimpleTabs/SimpleTabs";
import tourIcon from "../../../assets/svgs/tourIcon";

import { useLocation, useSearchParams } from 'react-router-dom';
import { format } from "path";
import { Navigate } from "../../../help-center/HelpCenter/HelpCenter";
import { Size } from "../../../types/sizes";

type ViewerProps = {
    guide?: Guide //If guide is not passed, then loading viewer will be shown. 
    onClose: () => void;
    onBack?: ()=>void;
    overrideDevice?:Device,
    navigator?:() => Navigate,
    size?:Size,
} 

type ViewerTab = "Article" | "Video" | "Tour"

export const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {

    var guide: Guide | undefined
    if(props.guide) guide = props.guide
    

    var device = useWindowSize().device;
    if(props.overrideDevice) device= props.overrideDevice
    const gigDevice = "gigauser-"+device

    const [position, setPosition] = useState<"left" | "right">("right")
    
    if(props.navigator){
        var [searchParams, setSearchParams] = useSearchParams();
    }

    const tourEnabled = props.guide && props.guide.guideData.tourEnabled && device!=="laptop"
    console.log("tourEnabled: ", tourEnabled)

    const loading = props.guide?false: true

    var isTourVisible = false 
    var isArticleVisible = false 
    var isVideoVisible = false

    if(tourEnabled){
        isTourVisible = true
    }
    if(!props.guide?.guidePreview.hideArticle){
        isArticleVisible = true
        
    }
    if(props.guide?.guidePreview.publishedVideo!=="none"){
        isVideoVisible = true
    }

    const initFormat: () =>ViewerTab = () =>{
        //Let's initiate the active tab

        var formatValue: ViewerTab = "Article"

        if(props.navigator){
            try{
                // const location = useLocation();
                // const searchParams = new URLSearchParams(location.search);
                formatValue = searchParams.get('format') as ViewerTab;
                
            }
            catch{
                formatValue="Article"
            }
        }
        

        if(formatValue==="Article" || !formatValue){
            if(isArticleVisible) return "Article"
            else if(isVideoVisible) return "Video"
            else return "Tour"
        }
        else if(formatValue==="Video"){
            if(isVideoVisible) return "Video"
            else if(isArticleVisible) return "Article"
            else return "Tour"
        }
        else if(formatValue==="Tour"){
            if(isTourVisible) return "Tour"
            else if (isArticleVisible) return "Article"
            else return "Video"
        }

        else return "Article"
    }

    const [activeTab, setActiveTab] = useState<string>(initFormat());

    const closeHelpCenter = () =>{
        props.onClose()
    }

    const brandColor = getBrandingColor()


    var tabs = [
        {
            text: "Tour",
            icon: tourIcon, 
            hidden: !isTourVisible
        }, 
        {
            text: "Article", 
            icon: readIcon, 
            hidden: !isArticleVisible, 
        }, 
        {
            text: "Video", 
            icon: watchIcon, 
            hidden: !isVideoVisible
        }
    ]


    var videoSrc = props.guide?.guideData.video.src || ""
    if(props.guide?.guidePreview.generatedVideoExists && props.guide.guidePreview.publishedVideo==="ai"){
        //We need to show an AI video
        const aiSrc = props.guide?.guideData.video.metadata.generatedVideo?.src
        if(aiSrc) videoSrc = aiSrc
    }
    else if (props.guide?.guidePreview.editedVideoExists){

        //Not AI mode and an edited video exists
        const editedSrc = props.guide?.guideData.video.metadata.editedVideo?.src
        if(editedSrc) videoSrc = editedSrc
    }

    

    const getFormatFromActiveTab = () =>{

        
        if(!props.guide) return <h1>Couldn't get guide!</h1>
        

        const tourComponent = <TourViewer tour={props.guide.guideData.tour} onClose={closeHelpCenter}></TourViewer>
        const articleComponent = <TextEditor size={props.size} initialContent={props.guide.guideData.plainDoc.data as any} editable={false}  />
        const videoComponent = <Video video={props.guide.guideData.video} videoSrc = {videoSrc}/>


        if(props.size==="l"){
            return(
                <>
                {
                    props.guide.guideData.video.src && isVideoVisible?
                    
                    <div className="Viewer-l-video">
                        {videoComponent}
                    </div>

                    :null
                }
                    
                    {articleComponent}

                </>
            )
        }

        if(activeTab==="Tour"){
            return tourComponent
        }
        else if(activeTab==="Article"){
            return articleComponent
        }
        else if (activeTab==="Video"){
            return videoComponent
        }
    }

    const changeTab = (tab: ViewerTab) => {

        setActiveTab(tab)
 
        if(props.navigator){
            try{
                // Set the new value for the "format" parameter in the search parameters
                searchParams.set('format', tab);

                // Update the search parameters
                setSearchParams(searchParams);
                
            }
            catch(e){
                console.log("format set tab: " , e)
            }
        }

    }

    return (
        <div className={`gigauser-guide-viewer-container ${gigDevice}`}>
            
            {props.onBack?
            <div className={`gigauser-viewer-header ${gigDevice}`}>
              
                
                    <div className={`gigauser-viewer-back ${gigDevice}`} onClick={props.onBack}>

                        <Icon className={`gigauser-viewer-back-button ${gigDevice}`} >
                            {downArrowheadIcon(device==="laptop"?brandColor:"white")}
                        </Icon>

                        {device==="laptop"?<span className="gigauser-back-text">Back</span>:null}

                        {
                            device==="mobile" && props.guide?
                            <div className="gigauser-header-title">{props.guide.guidePreview.header.name}</div>
                            :null
                        }
                            
                    </div>
                
                <Icon className="gigauser-viewer-close-helpcenter" onClick={closeHelpCenter} style={{
                    visibility: device==="laptop"?"hidden":"visible"
                }}>
                    {closeIcon(device==="laptop"?brandColor:"white")}
                </Icon>
                
            </div>
            :null}
            
            <div className={`gigauser-viewer-body style-scroll-square ${gigDevice}`}>

            
            <div className={`gigauser-viewer-title ${loading?"gigauser-loading-title":"gigauser-not-loading"}`}>

                <div
                    className={`gigauser-viewer-title-text gigauser-name ${props.size}`}
                >{props.guide? props.guide.guidePreview.header.name: "Random guide name here"}</div>

                <div
                    className={`gigauser-viewer-title-text 
                        ${loading?"":"gigauser-description"} 
                    ${props.size}`}  
                >{props.guide?props.guide.guidePreview.header.description: "Description of guide"}</div>


            </div>
            
            <div className="gigauser-viewer-tabs" style={{
                visibility:props.guide?"visible":"hidden"
            }}>

            {
                props.size!=="l"?
                <SimpleTabs
                    // tabs={["Tour", "Read", "Watch"]}
                    // tabs={guideData.tourEnabled ? ["Tour", "Read", "Watch"]:["Read", "Watch"]}
                    tabs = {tabs}
                    activeTab={activeTab}
                    setActiveTab={changeTab as (t:string) => void}
                />
                :null
            }
            
            </div>


            {
                props.guide?

                <div className="gigauser-guide-container style-scroll">
                
                    {getFormatFromActiveTab()}
                    
                </div>
                :

                <div className="gigauser-guide-container gigauser-loading-guide style-scroll">

                    <div className="gigauser-loading-p"> This is a random step 1 </div>
                    <div className="gigauser-loading-img"> This is a random loading img</div>
                    <div className="gigauser-loading-p"> This is a random step 1 </div>
                    <div className="gigauser-loading-img"> This is a random loading img</div>



                </div>


            }

            </div>


            
           

        </div>
    );
};
