import { useActive, useChainedCommands, useCommands, useRemirrorContext } from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import blockquote from "../../../../../../assets/svgs/text-editor-toolbar/blockquote"
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import "./BlockquoteButton.css"
type BlockquoteButtonProps = {};



const BlockquoteButton: React.FC<BlockquoteButtonProps> = () => {
    // const active = useActive();
    const commands = useChainedCommands() as any;
    const handler = (color?:string) => {
        commands.toggleBlockquote(color).run()
    };

    const active  = ()=>false
    // taskList: {
    //     label: "taskList",
    //     active: () => false,
    //     handler: () => {
    //         commands.toggleTaskList().focus().run();
    //     },
    //     hoverTip: {
    //         text: "Task List",
    //     },
    // }


    return (
        <>
        <div className="gigauser-texteditor-toolbar-blockquote-dropdown">
            <ToolbarButton
            active={active}
            handler={()=>handler()}
            source={blockquote()}
            />
            <div className="gigauser-texteditor-toolbar-blockquote-dropdown-content">
            <ToolbarButton
            active={active}
            handler={()=>handler("gray")}
            source={blockquote()}
            backgroundColor={"#f2f2f7"}
            />
            <ToolbarButton
            active={active}
            handler={()=>handler("blue")}
            source={blockquote()}
            backgroundColor={"#ddf7ff"}
            />
            <ToolbarButton
            active={active}
            handler={()=>handler("green")}
            source={blockquote()}
            backgroundColor={"rgb(217, 248, 217)"}
            />

            <ToolbarButton
            active={active}
            handler={()=>handler("yellow")}
            source={blockquote()}
            backgroundColor={"rgb(250, 240, 200)"}
            />
            <ToolbarButton
            active={active}
            handler={()=>handler("red")}
            source={blockquote()}
            backgroundColor={"rgb(256, 220, 220)"}
            />
            </div>
        </div>
        
        </>
        
        
    );
};
export default BlockquoteButton;
