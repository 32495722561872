import folder_color from '@gigauser/common/src/assets/svgs/folder_color';
import { Collection, CollectionChildren } from '@gigauser/common/src/core/types/collections';
import { GuidePreview } from '@gigauser/common/src/core/types/guide';
import React, { useContext, useEffect, useState } from 'react';
import './CollectionViewer.css'

import ExtHelpCenterContext from '../ExtHelpCenterContext';
import useOpenGuide from '../../../hooks/useOpenGuide';
import useOpenCollection from '../../../hooks/useOpenCollection';
import { useAppSelector } from '../../../redux/useAppRedux';
import { rootCollection } from '@gigauser/common/src/types/files';
import { getBrandingColor } from '@gigauser/common/src/core/utils/styleUtils';
import documentIcon from '@gigauser/common/src/assets/svgs/documentIcon';
import Icon from '@gigauser/common/src/ui/Icon/Icon';

type CollectionViewerProps = {
    collection: Collection;
};

const CollectionViewer:React.FC<CollectionViewerProps> = ({collection, ...props}) => {

    const [guides, setGuides] = useState<GuidePreview[]>([])
    const [collections ,setCollections] = useState<Collection[]>([])

    const helpCenterContext = useContext(ExtHelpCenterContext)

    const initHomeContent = async() =>{

        var  parentId = collection?collection.id : undefined

        const res = await helpCenterContext.fetchAllChildren(parentId)
        const {collections, guidePreviews} = res

        setGuides(guidePreviews)
        setCollections(collections)

    }

    useEffect(() =>{
        initHomeContent()
    }, [collection])


    const collectionName = collection?.name === rootCollection.name ? 
        `${helpCenterContext.getOrganization()?.name} Help Center` : collection.name

    const collectionDescription = collection?.description === rootCollection.description ?
        `Home` : collection.description
    
    
    return (
        
        <div className="CollectionViewer">

            <div className='CollectionViewer-header'>
                <div className='CollectionViewer-title'>
                    {collectionName}
                </div>

                <div className='CollectionViewer-description'>
                    {collectionDescription}
                </div>

                <div className='CollectionViewer-subtitle'>
                    {guides.length?`${guides.length} guides `: ""}
                    {guides.length && collections.length? <>&middot;</> :""}
                    {collections.length?` ${collections.length} sub-collections`:""}
                </div>

            </div>
            
            
            {
                guides.length?
                <>
                    <div className='guides-title'>Articles</div>
                    <div className='browser-guides-container'>
                    {
                        guides.map(
                            guidePreview => 
                                <GuideBox guidePreview={guidePreview}></GuideBox>
                        )
                    }
                    </div>
                    
                </>
                
                :null
            }

            {
                collections.length?
                <>
                    <div className='guides-title'>Collections</div>
                    <div className='browser-guides-container'>
                    {
                        collections.map(
                            collection => 
                                <CollectionBox collection={collection} ></CollectionBox>
                        )
                    }

                    </div>
                    
                </>
                
                :null
            }


            </div>
    )
}


type GuideBoxProps = {
    guidePreview: GuidePreview

}

const GuideBox:React.FC<GuideBoxProps> = ({guidePreview, ...props}) => {

    const onOpenGuide =  useOpenGuide()
    const collectionPath = useAppSelector(state => state.hc.collectionPath)
    const appTheme = useAppSelector(state => state.hcUi.theme)
    const onClick = () => {
        onOpenGuide(guidePreview, collectionPath)
    }

    return (

        <div className='Browser-GuideBox-v2 BrowserBox' onClick={onClick}>

            <Icon className='Browser-GuideBox-icon'>
                {documentIcon(getBrandingColor(appTheme))}
            </Icon>
            
            <div className="Broswer-GuideBox-v2-text">
                <div className='Browser-GuideBox-v2-title browserItem-title'>{guidePreview.header.name}</div>
                <div className='Browser-GuideBox-v2-description browserItem-description'>
                    {guidePreview.header.description}
                </div>

            </div>

        </div>

    )
}

type CollectionBoxProps={
    collection: Collection,
}

const CollectionBox: React.FC<CollectionBoxProps> = ({collection, ...props}) => {

    const onOpenCollection = useOpenCollection()
    const collectionPath = useAppSelector(state => state.hc.collectionPath)

    const onClick = () => {
        onOpenCollection(collection, collectionPath)
    }

    const description = collection.description || "Click to learn more"
    const appTheme = useAppSelector(state => state.hcUi.theme)

    return (
        <div className='Browser-CollectionBox-v2 BrowserBox' onClick={onClick}>

            <div className="Browser-CollectionBox-v2-folder">{folder_color}</div>
            <div className="Browser-CollectionBox-v2-title browserItem-title">
                <div className='Browser-CollectionBox-v2-title-text'>
                    {collection.name}
                </div>
            </div>
            <div className="Browser-CollectionBox-v2-description browserItem-description">{description}</div>

            <div className='Browser-CollectionBox-v2-explore'>Explore  &rarr; </div>


        </div>
    )
}

export default CollectionViewer;