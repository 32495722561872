import React, { useEffect, useRef } from 'react';
import './HelpCenterViewer.css'
import { Guide, GuidePreview } from '@gigauser/common/src/core/types/guide';
import GridLoader from '@gigauser/common/src/ui/loaders/GridLoader/GridLoader';
import { Viewer } from '@gigauser/common/src/components/Guide/Viewer/Viewer';
import { Collection } from '@gigauser/common/src/core/types/collections';
import Breadcrumbs from '@gigauser/common/src/ui/tabs/BreadcrumbsReadonly/Breadcrumbs';
import CollectionViewer from '../../CollectionViewer/CollectionViewer';
import logger from '@gigauser/common/src/utils/logger';
import { useAppDispatch, useAppSelector } from '../../../../redux/useAppRedux';
import useOpenGuide from '../../../../hooks/useOpenGuide';
import { setViewerComponent } from '../../../../redux/slices/hcUiSlice';
import useWindowSize from '@gigauser/common/src/hooks/useWindowSize';
import { indexbarThreshold, tocThreshold } from '../../layout';

type HelpCenterViewerProps = {
    loading: boolean,
    onClickCollectionBreadcrumbs: (collection: Collection) => Promise<void>

};

const HelpCenterViewer:React.FC<HelpCenterViewerProps> = (props) => {

    const containerRef = useRef<HTMLDivElement>(null);

    const collectionPath = useAppSelector(state => state.hc.collectionPath)
    console.log("collectionPath: ", collectionPath)

    const dispatch = useAppDispatch()
    const guide = useAppSelector(state => state.hc.currentGuide)

    const windowSize = useWindowSize()
    const appTheme = useAppSelector(state => state.hcUi.theme)

    useEffect(() => {
        
        if(containerRef.current) dispatch(setViewerComponent(containerRef.current))

    }, [containerRef])
    
    return (
        <div className={`HelpCenterViewer ${windowSize.width<indexbarThreshold?"remove-margin":""}`} ref={containerRef}>

            <div className={`ViewerContainer ${guide?"GuideViewer":"CollectionViewer"}`} >

                {
                    collectionPath?
                    <div className="gigauser-helpcenter-breadcrumbs"> 
                        <Breadcrumbs appTheme={appTheme} navigationHandler={props.onClickCollectionBreadcrumbs} segments={collectionPath}  ></Breadcrumbs>
                    </div>
                    :null
                }

                {
                    props.loading ?
                    <GridLoader center />
                    :
                    guide ?
                        <Viewer
                            guide= {guide.guideData?guide as Guide:undefined }
                            // guide={undefined}
                            onClose={() =>{}}
                            overrideDevice={"laptop"}
                            size='l'

                            // navigator={() => {}}
                        />
                    :
                        <CollectionViewer 
                            collection={collectionPath[collectionPath.length - 1]}
                        />
                }
                
            </div>
            
            
            <div className={`TocContainer ${windowSize.width<tocThreshold?"hide":""}`}>
                {/* Contents */}
            </div>
            
            

        </div>
    )

}
export default HelpCenterViewer;