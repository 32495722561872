import * as React from "react";
import { GuidePreview, GuideHeader, GuideData, Guide, Organization } from "../../core/types/guide";
import GuidesList from "../../components/guides/GuidesList/GuidesList";
import { Viewer } from "../../components/Guide/Viewer/Viewer";
import "./HelpCenter.css"
import { CollectionChildren, Collection } from '../../core/types/collections';
import { GigaUserApi } from '@giga-user-fern/api';
import {useState, useRef, useEffect} from 'react';
import searchIcon from "../../assets/svgs/searchIcon";
import Icon from "../../ui/Icon/Icon";
import Ring from '../../ui/Loading/Ring/Ring'
import fuzzysort from 'fuzzysort'
import Footer from "../../components/Footer/Footer";
import closeIcon from "../../assets/svgs/closeIcon";
import empty_search_icon from "../../assets/svgs/empty_search"
import ContentNavigator from "../ContentNavigator/ContentNavigator";
import useWindowSize from "../../hooks/useWindowSize"
import { Device } from "../../types/devices";
import { getPathFromRoute, isProdDomain } from '../utils/routeUtils';
import { rootCollection } from "../../types/files";
import { captureEvent } from '../../core/analytics/analytics';
import messageIcon from "../../assets/svgs/messageIcon";
import logger from "../../utils/logger";

const Id = GigaUserApi.Id

export type Navigate = (a: string)=>void

export interface HelpCenterProps {
    
	// id?: GigaUserApi.Id; // The collection ID that we need
    // collectionChildren: CollectionChildren, //root level collections and guides. Only for initialisation.
	fetchAllChildren: (collectionId?: string) => Promise<CollectionChildren>,
	fetchGuideData: (id: GigaUserApi.Id) => Promise<GuideData | null>, 
    fetchAllGuidePreviews: () => Promise<GuidePreview[]>,
    fetchGuidePreview:(id: GigaUserApi.Id) => Promise<GigaUserApi.guides.GuidePreview | null>,
    getPath: (id: GigaUserApi.Id) => Promise<false | [Collection, ...Collection[]]>,
	close: ()=>void
    search: (searchString: string)=>Promise<GuidePreview[]>
    getOrganization:() => Organization | null,

    //OPTIONAL
    overrideDevice?:Device,
    navigator?:() => Navigate,
    supportFunction?: ()=>void

} 

export type SearchResult =  {
    highlightedName: (string | JSX.Element)[] | null,
    guidePreview: GuidePreview
}

export type Searchable = {
    id: GigaUserApi.Id,
    name: string ,
    description: string,
}

export default function HelpCenter(props: HelpCenterProps) {

    var navigate:Navigate|undefined

    if(props.navigator){
        navigate = props.navigator()
    }
  
    var device = useWindowSize().device;
    if(props.overrideDevice) device= props.overrideDevice
    const gigDevice = "gigauser-"+device

    const brandColor = getComputedStyle(document.documentElement).getPropertyValue('--gigauser-brand-color');

    const [guide, setGuide] = React.useState<Guide| false | "loading">(false);

    const [collectionPath, setCollectionPath] = useState<[Collection, ...Collection[]]>([rootCollection])
    const [loadingCollectionPath, setLoadingCollectionPath] = useState(props.navigator?true:false)

    const [searchInProgress, setSearchInProgress] = React.useState(false)
	const [searchString, setSearchString] = useState("")
    const [searchResults, setSearchResults] = useState<SearchResult[]>([])

    const [noResults, setNoResults] = useState<boolean>(false)

    let timer: ReturnType<typeof setTimeout>   

    const [filesToSearch, setFilesToSearch] = useState<Searchable[]>([])

    // const filesToSearch = useRef(collectionChildren.guidePreviews.map(guidePreview=> {
    //     return{
    //         "name": guidePreview.header.name, 
    //         "description": guidePreview.header.description,
    //         "id":guidePreview.id
    //     }
    // }))

	const debounceSearchTimer = useRef<any>(null)
    
    const navigateToLink = (string:string) =>{

        logger.debug("time to navigate to link: ", string, process.env)

        if(navigate){
            const newURL = string + (!isProdDomain() ?`?org=${props.getOrganization()?.id}` : '')
            logger.debug("newURL=", newURL)
            navigate(newURL)
        }

    }

    const runSearch = async (searchString: string) =>{
        
        logger.debug("search in progress")
        setSearchInProgress(true)        

        // const fuzzyResults = fuzzysort.go(searchString, filesToSearch, {key:'name'})
        // const res = fuzzyResults.map(res=>{
        //     logger.debug("res: ", res)
        //     return{
        //         highlightedName: fuzzysort.highlight(res, (m,i) =><span className="bolden" key={i}>{m}</span>),
        //         // highlightedName: fuzzysort.highlight(res) as string,
        //         guidePreview: {
        //             id: res.obj.id,
        //             header: {
        //                 "name": res.obj.name,
        //                 "description": res.obj.description
        //             }
        //         } as GuidePreview
        //     }
        // })
        captureEvent({eventName: "SearchRunInHelpCenter", value: {searchString}})
        const res = await props.search(searchString)
        if (res.length === 0){
            captureEvent({eventName: "SearchReturnsNoResults", value: {searchString}})
            const fuzzyResults = fuzzysort.go(searchString, filesToSearch, {key:'name'})
            const res = fuzzyResults.map(res=>{
                logger.debug("res: ", res)
                return{
                    highlightedName: fuzzysort.highlight(res, (m,i) =><span className="bolden" key={i}>{m}</span>),
                    // highlightedName: fuzzysort.highlight(res) as string,
                    guidePreview: {
                        id: res.obj.id,
                        header: {
                            "name": res.obj.name,
                            "description": res.obj.description
                        }
                    } as GuidePreview
                }
            })
            setSearchInProgress(false)
            setSearchResults(res)
        }
        else{
            logger.debug("searchResults: ", searchResults)
            setSearchInProgress(false)
            // setSearchResults(res)
            logger.debug("search in progress done!")

            logger.debug("searchResults: ", res)
            setSearchResults(res.map((x)=>{return {
                guidePreview: x, 
                highlightedName: null
            }}))
        }
        

    }

    const popStateFunction = async () => {

        //TODO: Fix this ...
        
        logger.debug("popstate time!")

        const result = await getPathFromRoute({
            getPath: props.getPath, fetchGuidePreview: props.fetchGuidePreview,
            onLoadingGuide: () =>{setGuide("loading")},
            onLoadingCollection: ()=>{
                setGuide(false)
                setLoadingCollectionPath(true)
            }
        });

        if(!result){
            navigateToLink("/folders/"+rootCollection.id)
            throw new Error("Unexpected url!!")
        }
        else{
            if(result.type==="folders"){
                logger.debug("popstate folders newPath: ", result.newPath)
                setGuide(false)
                setLoadingCollectionPath(false)
                setCollectionPath(result.newPath)
            }
            else{
                const preview = result.guidePreview
                const data = await props.fetchGuideData(preview.id)
                setSearchString("")
                setGuide({
                    id: preview.id,
                    guidePreview: preview,
                    guideData: data!
                })
            }
        }

        // const newPath = [rootCollection]
        // store.dispatch(updateCollectionPath(newPath as [Collection, ...Collection[]]));
    }

    useEffect(() =>{

        // -------- Initialise the saver ------------------

        props.fetchAllGuidePreviews().then((res: GuidePreview[]) =>{
            logger.debug("got res: ", res)
            if(res){
                setFilesToSearch(res.map((item)=>{
                    return{
                        id: item.id, 
                        name: item.header.name, 
                        description: item.header.description
                    } as Searchable
                } ))
            }
        })

        //-------- Check what to initialise as collectionPath ----------

        popStateFunction()

        // ------- Handle browser forward and back operations ----------

        window.addEventListener("popstate", popStateFunction)

        // 👇️ remove the event listener when the component unmounts
        return () => {
            logger.debug("removing contentnavigator popstate")
            window.removeEventListener('popstate', popStateFunction);
        };

    }, [])

	const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) =>{

		setSearchString(e.target.value)

		if(debounceSearchTimer.current!==undefined){
            clearTimeout(debounceSearchTimer.current)
        }

		debounceSearchTimer.current = setTimeout(() =>{
            logger.debug("running search!: ", e.target.value)
            runSearch(e.target.value)
        }, 300)

	}

    const onCloseGuide = () =>{
        
        setGuide(false)
        setLoadingCollectionPath(false)
        setCollectionPath([rootCollection])
        navigateToLink("/folders/Collection_root")
        
    }

    const onOpenGuide = async(preview: GuidePreview) => {

        const data = await props.fetchGuideData(preview.id)
        setSearchString("")
        setGuide({
            id: preview.id,
            guidePreview: preview,
            guideData: data!
        })
        logger.debug("onopenguide navigator: ", props.navigator)
        captureEvent({eventName: "GuideOpenedInHelpCenter", value: {guideID: preview.id}})
        navigateToLink('/guide/'+preview.id)

    }    

    const debouncedSetNoResults = () =>{

        if(timer!==undefined){
            logger.debug("clearing Timeout!")
            clearTimeout(timer)
        }
        timer = setTimeout(() =>{
            
            logger.debug("setting noResults: true")
            setNoResults(true)
            
        }, 3000)
        
    }

    const closeHelpCenter = () =>{
        
        props.close()

    }
    

    const organization = props.getOrganization? props.getOrganization():null

    const searchHeaderComponent = (
        <div className={`gigauser-helpcenter-search-header ${gigDevice}`}>

                    <div className={`gigauser-helpcenter-search-header-child ${gigDevice}`}>
                    {
                        device==="laptop"?
                        <div className="gigauser-helpcenter-laptop-topheader">
                            <div>{organization? <span> <b>{organization.name}</b> | Help </span>:"Help"}</div>
                            <div>Go to  <a className="gigauser-domain-link" href={"https://"+organization?.domain} target="_blank">{organization?.name}</a></div>
                        </div>
                        :null
                    }

                    <center className="gigauser-helpcenter-center">
					<div className={`gigauser-helpcenter-searchbar ${gigDevice}`}>

                        {
                            
                            searchInProgress?
                            <div className="gigauser-search-loader">
                                <Ring height={20} color="white" />
                            </div>
                            :
                            <Icon className={`gigauser-search-icon ${gigDevice}`}>
                                {searchIcon("white")}            
                            </Icon>

                        }
                        
						<input className={`gigauser-search-input ${gigDevice}`} placeholder={filesToSearch.length?"Search for help...":"Loading search ..."}
                            disabled={filesToSearch.length?false:true}
							onChange={onChangeSearchInput}
						>
						</input>

                        

                    </div>
                    </center>

                        {
                            device==="mobile"?
                            <Icon className="gigauser-close-helpcenter" onClick={closeHelpCenter}>
                                {closeIcon("#ffffff")}
                            </Icon>
                            :null
                        }
                        
                    </div>

        </div>
    )

    if (guide === false || searchString) {
		// Displaying collection
		// We have guides and guide previews

        if(searchResults.length==0 && searchString.length){
            logger.debug("need to debounce set noResults")
            debouncedSetNoResults()
        }
        else{
            if(noResults) setNoResults(false)
        }
        logger.debug("noResults: ", noResults, searchResults.length, props.supportFunction)

        return (
            <div className={`gigauser-helpcenter-container ${gigDevice} `}>

				{searchHeaderComponent}

                <div className={`gigauser-helpcenter-guides-container style-scroll-square ${gigDevice}`}>
                {
                    searchResults && searchString.length && !searchInProgress?
                    <div className={`gigauser-guideslist-container ${gigDevice}`}>

                        {
                            searchResults.length?
                            <>
                            <h3 className="gigauser-guideslist-title-help">
                                Search results
                            </h3>
                            <GuidesList
                                guidePreviews={searchResults.map(res=>res.guidePreview)} 
                                onOpen={onOpenGuide}
                                loading={false}
                                showPublishedStatus={false}
                                // onClose={onCloseGuide}
                            ></GuidesList>
                            </>
                            :
                            
                            noResults?
                            <div className={`gigauser-emptysearch ${gigDevice}`}>
                                <Icon className="gigauser-emptysearch-icon"> 
                                    {empty_search_icon}
                                </Icon>
                                <div className="gigauser-emptysearch-title">Try again</div>
                                <div className="gigauser-emptysearch-subtitle">No search results for &nbsp; 
                                    <b>{searchString}</b>
                                </div>

                                {props.supportFunction? 
                                    <div className="gigauser-contact-support" onClick={()=>{
                                        props.supportFunction?.()
                                    }}>
                                        <div>Contact Support</div>
                                        <Icon className="gigauser-airplane-icon">
                                            {messageIcon()}
                                        </Icon>
                                    </div>
                                    
                                :null}

                            </div>
                            :
                            <>
                            <h3 className="gigauser-guideslist-title-help">
                                Searching ...
                            </h3>
                            <GuidesList
                                guidePreviews={searchResults.map(res=>res.guidePreview)} 
                                onOpen={onOpenGuide}
                                loading={true}
                                showPublishedStatus={false}
                                // onClose={onCloseGuide}
                            ></GuidesList>    
                            </>                      
                            
                        }
                        
                        
                    </div>
                    :
                    <ContentNavigator
                        
                        //unchanging props
                        overrideDevice={props.overrideDevice}
                        showPublishedStatus={false}
                        fetchAllChildren={props.fetchAllChildren}
                        onOpenGuide={onOpenGuide}
                        navigator = {props.navigator}
                        getPath={props.getPath}
                        getOrganization={props.getOrganization}

                        //changing props
                        collectionPath={loadingCollectionPath?undefined:collectionPath}
                        setCollectionPath={setCollectionPath}
                    />
                }

                </div>

                <Footer supportFunction={props.supportFunction} />

            </div>
        );

    }
    else{
        logger.debug("viewer")
		return(
            <div className={`gigauser-helpcenter-container ${gigDevice} `} style={{
                overflow: "hidden"
            }}>
                {
                    device==="laptop"?searchHeaderComponent:null
                }
                <div className={`gigauser-helpcenter-guides-container ${gigDevice}`} style={{
                overflow: "hidden"
            }}>
                    <Viewer
                        guide = {guide=="loading"?undefined:guide}
                        onClose={props.close}
                        onBack = {onCloseGuide}
                        overrideDevice={props.overrideDevice}
                        navigator={props.navigator}
                    ></Viewer>
                </div>
                
                <Footer />

            </div>
			
		)
	}
}
