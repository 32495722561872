import React, { useContext, useEffect, useState } from 'react';
import './HelpCenterHome.css'
import HelpCenterHomeTopbar from '../HelpCenterHomeTopbar/HelpCenterHomeTopbar';
import { Collection, CollectionChildren } from '@gigauser/common/src/core/types/collections';
import { Guide, GuidePreview } from '@gigauser/common/src/core/types/guide';
import Icon from '@gigauser/common/src/ui/Icon/Icon';
import folder_color from '@gigauser/common/src/assets/svgs/folder_color';
import ExtHelpCenterContext from '../../ExtHelpCenterContext';
import {  Navigate } from '@gigauser/common/src/help-center/HelpCenter/HelpCenter';
import { isProdDomain } from '@gigauser/common/src/help-center/utils/routeUtils';
import { useAppDispatch } from '../../../../redux';
import { setSearchVisible } from '../../../../redux/slices/hcUiSlice';


type HelpCenterHomeProps = {

};

const HelpCenterHome:React.FC<HelpCenterHomeProps> = (props) => {
    var navigate:Navigate|undefined

    const [guides, setGuides] = useState<GuidePreview[]>([])
    const [collections ,setCollections] = useState<Collection[]>([])

    const [loadingSearch, setLoadingSearch] = useState(false)
    const [searchResults, setSearchResults] = useState<GuidePreview[]>([])

    const helpCenterContext = useContext(ExtHelpCenterContext)
    const dispatch = useAppDispatch()

    if(helpCenterContext.navigator){
        navigate = helpCenterContext.navigator()
    }

    const initHomeContent = async() =>{
        const res = await helpCenterContext.fetchAllChildren()
        const {collections, guidePreviews} = res

        setGuides(guidePreviews)
        setCollections(collections)

    }

    const navigateToLink = (string:string) =>{

        if(navigate){
            const newURL = string + (!isProdDomain() ?`?org=${helpCenterContext.getOrganization()?.id}` : '')
            navigate(newURL)
        }
        
    }

    const onClickCollection = (collection: Collection) =>{
        navigateToLink('/folders/'+collection.id)
    }

    const onClickGuide = (guidePreview: GuidePreview) =>{
        navigateToLink('/guide/'+guidePreview.id)
    }

    useEffect(() =>{
        initHomeContent()
    }, [])

    console.log("the results are: ", searchResults)

    
    return (
        <div className='HelpCenterv2-home-container'>

            <HelpCenterHomeTopbar 
                setLoadingSearch={setLoadingSearch}
                loadingSearch={loadingSearch} setSearchResults={setSearchResults} 
            />

            <div className="HelpCenterv2-home-contents">

            {

                searchResults.length?
                <>
                    <div className='guides-title'>Search Results</div>
                    <div className='home-guides-container'>
                    {
                        searchResults.map(
                            guidePreview => <GuideBox guidePreview={guidePreview} onClickGuide={onClickGuide}></GuideBox>
                        )
                    }
                    </div>
                </>
                :null
                
            }
            
            {
                guides.length && searchResults.length==0?
                <>
                    <div className='guides-title'>Articles</div>
                    <div className='home-guides-container'>
                    {
                        guides.map(
                            guidePreview => <GuideBox guidePreview={guidePreview} onClickGuide={onClickGuide}></GuideBox>
                        )
                    }
                    </div>
                    
                </>
                
                :null
            }

            {
                collections.length && searchResults.length==0?
                <>
                    <div className='guides-title'>Collections</div>
                    <div className='home-guides-container'>
                    {
                        collections.map(
                            collection => <CollectionBox collection={collection} onClickCollection={onClickCollection}></CollectionBox>
                        )
                    }

                    </div>
                    
                </>
                
                :null
            }


            </div>

        </div>
    )
}

type GuideBoxProps = {
    guidePreview: GuidePreview, 
    onClickGuide: (guide: GuidePreview) => void
}

const GuideBox:React.FC<GuideBoxProps> = ({guidePreview, ...props}) => {

    const onClick = () =>{
        props.onClickGuide(guidePreview)
    }

    return (

        <div className='GuideBox-v2 HomeBox' onClick={onClick}>
            
            <div className='GuideBox-v2-title homeItem-title'>{guidePreview.header.name}</div>
            <div className='GuideBox-v2-description'>{guidePreview.header.description}</div>

        </div>

    )
}

type CollectionBoxProps={collection: Collection, onClickCollection: (collection: Collection) => void}

const CollectionBox: React.FC<CollectionBoxProps> = ({collection, ...props}) => {

    const onClick = () =>{
        props.onClickCollection(collection)
    }

    return (
        <div className='CollectionBox-v2 HomeBox' onClick={onClick}>

            <div className="CollectionBox-v2-folder">{folder_color}</div>
            <div className="CollectionBox-v2-title homeItem-title">{collection.name}</div>
            <div className="CollectionBox-v2-description homeItem-description">{collection.description}</div>

            <div className='CollectionBox-v2-explore'>Explore  &rarr; </div>


        </div>
    )
}

export default HelpCenterHome;