import React, { useContext, useEffect } from 'react';
import Logo from '@gigauser/common/src/assets/logos/Logo.svg'
import './HelpCenterBrowserTopbar.css'
import Icon from '@gigauser/common/src/ui/Icon/Icon';
import searchIcon from '@gigauser/common/src/assets/svgs/searchIcon';
import { getBrandingColor, lightenColor } from '@gigauser/common/src/core/utils/styleUtils';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { setSearchVisible, setTheme } from '../../../../redux/slices/hcUiSlice';
import { helpSaver as saver } from '@gigauser/common/src/network/saver';
import useNavigateToLink from '../../../../hooks/useNavigateToLink';
import useOpenCollection from '../../../../hooks/useOpenCollection';
import { rootCollection } from '@gigauser/common/src/types/files';
import ExtHelpCenterContext from '../../ExtHelpCenterContext';
import { getOperatingSystem } from '@gigauser/common/src/utils/os';
import useWindowSize from '@gigauser/common/src/hooks/useWindowSize';
import logger from '@gigauser/common/src/utils/logger';
import ThemeSwitch from '@gigauser/common/src/ui/switches/ThemeSwitch/ThemeSwitch'

type HelpCenterBrowserTopbarProps = {
    
};

const HelpCenterBrowserTopbar:React.FC<HelpCenterBrowserTopbarProps> = () => {
    
    const dispatch = useAppDispatch()

    const onOpenSearch = () => {
        dispatch(setSearchVisible(true))
    }

    const openCollection = useOpenCollection()

    const navigateToHome = () => {
        openCollection(rootCollection, [rootCollection])
    }

    const helpCenterContext = useContext(ExtHelpCenterContext)

    const OS = getOperatingSystem()

    const handleKeyDown = (event: KeyboardEvent) => {
        console.log("handleKeyDown: ")
        if ((event.metaKey || event.ctrlKey) && event.key === "k") {
          onOpenSearch();
        } 
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
    }, [])

    const windowSize = useWindowSize()

    const threshold1 = 1150
    const threshold2 = 940

    const onDark = () => {
        dispatch(setTheme("gigauser-dark"))
        document.documentElement.style.backgroundColor = "#0f1117"
    }
    const onLight = () => {
        dispatch(setTheme("gigauser-light"))
        document.documentElement.style.backgroundColor = "white"

    }

    const appTheme = useAppSelector(state => state.hcUi.theme)

    return (
        <div className='HelpCenterBrowserTopbarContainer'>

            
            <div className='HelpCenterBrowserTopbar'>

                <div className='HelpCenterBrowserTopbar-left' >

                        <img onClick={navigateToHome} 
                            className={`brand-logo`} 
                            src={`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${saver.organization?.id}.svg`} />
                            
                        <div onClick={navigateToHome} className="HelpCenterBrowser-HomeText"
                            // style={{
                            //     display: windowSize.width>threshold2?"flex":"none"
                            // }}
                        >Help Center</div>

                </div>

                {
                        windowSize.width > threshold2?

                <div className='HelpCenterBrowserTopbar-center'
                >
                        <div className='HelpCenterBrowserTopbar-search'  onClick={onOpenSearch}>

                            <Icon className="searchIcon">{searchIcon(appTheme=="gigauser-light"?getBrandingColor():"white")}</Icon>

                            <div  className='HelpCenterBrowserTopbar-search-input'>
                                Search for help ...
                            </div>

                            <div className="hc-searchbar-shortcut">{OS==="MacOS"?"⌘ + K": "Ctrl + K"}</div>
                        </div>
                        
                </div>
                :
                <div className='HelpCenterBrowserTopbar-minisearch'>
                    <Icon className="searchIcon" onClick={onOpenSearch}>{searchIcon(getBrandingColor(appTheme))}</Icon>
                </div>
                }

                <div style={{
                    display: (windowSize.width>threshold1 || windowSize.width<threshold2)?"none":"flex",
                    color: "transparent"

                }}>
                    .
                </div>

                <div className='HelpCenterBrowserTopbar-right' style={{
                    display: windowSize.width>threshold1?"flex":"none"
                }}>

                    <div>Contact Us</div>

                    <div className="Login-CTA">Log in 
                        {/* to {helpCenterContext.getOrganization()?.name.split(' ')[0]} */}
                    </div>

                    <div className='ThemeSwitch-hcBrowserTopbar'>
                        <ThemeSwitch onDark={onDark} onLight={onLight} />
                    </div>

                </div>

            </div>

        </div>
    )
}
export default HelpCenterBrowserTopbar;