// export const createOverlay = ()=>{
//     const body = document.getElementsByTagName('body')[0]
//     const bigDiv = document.createElement('div')
//     bigDiv.style.height = window.innerHeight + "px"
//     bigDiv.style.width = window.innerWidth + "px"
//     bigDiv.style.zIndex = '10000'
//     body.appendChild(bigDiv)
//     return bigDiv
// }
import { findElement, getDomElementDataFromTarget } from "../findElement";
import { TourStep } from "../../types/record";
import { createSurroundingDivs, getBody, samePosition } from '../../utils/DOMUtils';
import "../tour.css";
import { isEqual } from "lodash";
import  { createExitTourModal } from "../message/ExitTourModal/ExitTourModal";
import logger from "../../../utils/logger";

export class BaseStep {
    step: TourStep
    
    blockingContainer: HTMLDivElement | null;
    target: HTMLElement | null;
    boundingBox: HTMLDivElement | null;
    controls: HTMLDivElement | null;
    editor: boolean;
    endButton?: HTMLElement;
    clickEnabled: HTMLElement[]
    observer?: MutationObserver
    lastRect?: DOMRect
    callback?: (currStep: TourStep) => void;
    stepIndex: number;
    exitModalVisible: boolean;
    constructor(
        step: TourStep,
        stepIndex: number,
        options: {
            editor: boolean;
        }
    ) {
        // We are going to save some details
        this.step = step
        this.blockingContainer = null;
        this.target = null;
        this.boundingBox = null;
        this.controls = null;
        this.stepIndex = stepIndex
        this.editor = options.editor;
        this.clickEnabled = [];
        this.exitModalVisible = false
        // this.callback = options.callback;
    }
    completeCallback = ()=>{}
    completeStep = ()=>{
        this.observer?.disconnect()
        this.completeCallback()
    }

    endTour = () =>{

        this.removeExitModal()

        logger.debug("I need to end tour!")
        this.observer?.disconnect()
        if (this.target) {
            logger.debug("got target: ", this.target)
            this.target.removeEventListener("mouseleave", this.disableClicks);
            this.target.removeEventListener("click", this.completeStep)
        }
        if (this.blockingContainer) {
            logger.debug("got blocking container: ", this.blockingContainer)

            this.enableClicks()
            const body = getBody();
            body.removeChild(this.blockingContainer);
        }
        this.blockingContainer = null;
        this.target = null;
        this.boundingBox = null;
        this.controls = null;
        this.clickEnabled = []
    }

    restartStep = ()=>{}

    removeStep = ()=>{}

    playStep = ()=>{}

    createBlockingContainer = () => {
        // Overlay div
        const body = getBody();
        const bigDiv = document.createElement("div");
        bigDiv.className = "gigauser-blocking-container";
        this.blockingContainer = bigDiv;
        body.appendChild(this.blockingContainer);
    };

    removeExitModal = (e?: MouseEvent) =>{
        /**
         * Pass e everywhere except on endTour function. 
         */
        this.exitModalVisible = false
        const element = document.getElementById("gigauser-ExitTourModal-Parent")
        if(element && element.parentNode){
            element.parentNode.removeChild(element);
            if(e){
                e.stopPropagation()
                e.preventDefault()
    
            }

        }
    }

    killClicks = (e: MouseEvent) => {
        // This exists to prevent any event listeners from the platform
        // getting triggered in this mode.
        logger.debug("KILLING:", e)

        if (this.clickEnabled?.includes(e.target as HTMLElement)) {
            logger.debug("clickEnabled: ", e.target)
            return;
        }

        if(!this.exitModalVisible){

            const {modalParent, clickEnabledElements} = createExitTourModal(this.removeExitModal, this.endTour)
            this.blockingContainer?.appendChild(modalParent)
            this.clickEnabled = this.clickEnabled.concat(clickEnabledElements)
            this.exitModalVisible = true
        }
        else{
            
        }

        e.stopPropagation();
        e.preventDefault();
        // TODO: Add stuff to say whoops wrong click, click here only?
    };

    disableClicks = () => {
        // if (this.editor) return
        if (!this.boundingBox || !this.blockingContainer) return;
        this.boundingBox.style.pointerEvents = "auto";
        this.blockingContainer.style.pointerEvents = "auto";
        this.blockingContainer.style.cursor = "not-allowed";
        document.addEventListener("click", this.killClicks, true);
        document.addEventListener("mousedown", this.killClicks, true)
    };

    enableClicks = () => {
        // if (!this.setKill) return
        if (!this.boundingBox || !this.blockingContainer) {
            return;
        }
        this.boundingBox.style.pointerEvents = "none";
        this.blockingContainer.style.pointerEvents = "none";
        this.blockingContainer.style.cursor = "auto";
        document.removeEventListener("click", this.killClicks, true);
        document.removeEventListener("mousedown", this.killClicks, true)

    };

    createControls = () => {
        if (!this.blockingContainer) return;
        const controlDiv = document.createElement("div");
        controlDiv.className =
            "gigauser-guided-message" + (this.editor ? " editor" : "");
      
        controlDiv.innerText = this.step.metadata.text;
        
        const endButton = document.createElement("button");
        endButton.onclick = this.removeStep;
        endButton.innerText = "End Tour";
        endButton.className = "gigauser-end-guided-tour";
        controlDiv.appendChild(endButton);
        this.endButton = endButton;
        this.clickEnabled.push(this.endButton)
        
        this.controls = controlDiv;
        this.blockingContainer.appendChild(controlDiv);
    };

    handleTourClicks = ()=>{
        // if (!this.editor) {
        //     this.disableClicks();
        // } else {
        //     this.enableClicks();
        // }
        this.disableClicks()
    }
    
    canPlay = async ()=>{
        try {
            const elem = await findElement(this.step);
            if (elem) {
                return elem
            }
            return false
        }
        catch(e){
            logger.error(e)
            return false
        }
    }

    createBoundingBox = () => {
        // Flashing box with a hole set up, so that if mouse is over this box
        // clicks go through the blockingContainer.
        // This also creates click through divs that can be deleted

        if (!this.target) {
            return;
        }
        // Add a mutation observer to the target
        // Here, we account for the element disappearing
        // And restart step
        this.lastRect = this.target.getBoundingClientRect()
        this.observer = new MutationObserver(async (mutations) =>{
            const elem = await findElement(this.step)
            
            logger.debug("MUTATION TRIGGERED", mutations )
            if (!document.body.contains(elem)) {
                this.observer?.disconnect()
                this.removeStep()
                this.playStep()
            }
            
            if (!samePosition(elem.getBoundingClientRect(), this.lastRect!)){
                logger.debug("TRIGGERED", elem , this.target)
                this.observer?.disconnect()
                this.removeStep()
                this.playStep()
            }
            else{
                logger.debug("POSITION IS SAME")
            }

        });
        this.observer.observe(document.body, {childList: true, subtree: true});
        //
        const { top, left, bottom, right } = createSurroundingDivs(
            this.target
        );
        this.blockingContainer?.appendChild(top);
        this.blockingContainer?.appendChild(left);
        this.blockingContainer?.appendChild(right);
        this.blockingContainer?.appendChild(bottom);
        
        const boundingRect = this.target.getBoundingClientRect();
        const box = document.createElement("div");
        box.className = "gigauser-flashing-container";
        box.style.left = boundingRect.x + "px";
        box.style.top = boundingRect.y + "px";
        box.style.width = boundingRect.width + "px";
        box.style.height = boundingRect.height + "px";
        if (this.editor){
            box.className = "gigauser-flashing-container editor"
        }
        this.boundingBox = box;
        this.boundingBox.onmouseenter = () => {
            setTimeout(this.enableClicks, 10);
        };
        this.blockingContainer?.appendChild(this.boundingBox);
    };

    destroy = () => {
        return
    };
}


