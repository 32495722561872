import { Navigate } from "@gigauser/common/src/help-center/HelpCenter/HelpCenter"
import { useContext } from "react"
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext"
import { isProdDomain } from "@gigauser/common/src/help-center/utils/routeUtils"

const useNavigateToLink: () => (link: string) => void = () => {

    var navigate:Navigate|undefined

    const helpCenterContext = useContext(ExtHelpCenterContext)

    if(helpCenterContext.navigator){
        navigate = helpCenterContext.navigator()
    }

    return (link : string) => {

        if(navigate){
            const newURL = link + (!isProdDomain() ?`?org=${helpCenterContext.getOrganization()?.id}` : '')
            navigate(newURL)
        }

    }

}

export default useNavigateToLink