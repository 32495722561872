
const closeIcon = (color?: string) =>{

    const fill = color || "#000000"
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={fill} width="100%" height="100%" viewBox="0 0 24 24" ><path fillRule="evenodd" clipRule="evenodd" d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z" /></svg>    
    )
}

export default closeIcon

