const documentIcon = (color = "#000000") => {
  return (
    <svg
      width="82%"
      height="100%"
      viewBox="0 0 103 126"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0093 1.67746C37.0825 0.603425 38.5379 0 40.0556 0H85.8333C95.3145 0 103 7.69259 103 17.1818V108.818C103 118.308 95.3145 126 85.8333 126H17.1667C7.6858 126 0 118.308 0 108.818V40.0909C0 38.5719 0.602894 37.1152 1.67598 36.0411L36.0093 1.67746ZM85.8333 11.4545H45.7778V40.0909C45.7778 43.254 43.2159 45.8182 40.0556 45.8182H11.4444V108.818C11.4444 111.981 14.0064 114.545 17.1667 114.545H85.8333C88.9937 114.545 91.5556 111.981 91.5556 108.818V17.1818C91.5556 14.0188 88.9937 11.4545 85.8333 11.4545ZM19.5369 34.3636H34.3333V19.5541L19.5369 34.3636ZM22.8889 68.7273C22.8889 65.5641 25.4508 63 28.6111 63H74.3889C77.5493 63 80.1111 65.5641 80.1111 68.7273C80.1111 71.8905 77.5493 74.4546 74.3889 74.4546H28.6111C25.4508 74.4546 22.8889 71.8905 22.8889 68.7273ZM22.8889 91.6364C22.8889 88.4732 25.4508 85.9091 28.6111 85.9091H74.3889C77.5493 85.9091 80.1111 88.4732 80.1111 91.6364C80.1111 94.7995 77.5493 97.3636 74.3889 97.3636H28.6111C25.4508 97.3636 22.8889 94.7995 22.8889 91.6364Z"
        fill={color}
      />
    </svg>
  );
};

export default documentIcon;
