import { Guide, GuideData, GuidePreview } from "@gigauser/common/src/core/types/guide"
import { useContext } from "react"
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext"
import { useAppDispatch, useAppSelector } from "../redux"
import { setCollectionPath, setCurrentGuide } from "../redux/slices/hcSlice"
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics"
import useNavigateToLink from "./useNavigateToLink"
import { selectViewerContainer } from "../redux/slices/hcUiSlice"
import { Collection } from "@gigauser/common/src/core/types/collections"

const useOpenGuide: () => (guidePreview: GuidePreview, parentSegments: [Collection, ...Collection[]]) => Promise<GuideData | null> = () =>{
    
    const helpCenterContext = useContext(ExtHelpCenterContext)
    const dispatch = useAppDispatch()
    const navigateToLink = useNavigateToLink()
    const viewerComponent = useAppSelector(selectViewerContainer)


    return async (guidePreview: GuidePreview, parentSegments: [Collection, ...Collection[]]) => {
        
        navigateToLink('/guide/'+guidePreview.id)

        //This is to set guide loading. 
        dispatch(setCurrentGuide({
            id: guidePreview.id, 
            guidePreview: guidePreview
        }))

        const data = await helpCenterContext.fetchGuideData(guidePreview.id)

        dispatch(setCurrentGuide({
            id: guidePreview.id,
            guidePreview: guidePreview,
            guideData: data!
        }))

        dispatch(setCollectionPath(parentSegments))

        captureEvent({eventName: "GuideOpenedInHelpCenter", value: {guideID: guidePreview.id}})

        if(viewerComponent) viewerComponent.scrollTop = 0

        return data

    }

}


export default useOpenGuide

