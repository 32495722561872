import logger from "../../utils/logger";

export const getBody = () => {
    const body = document.body;
    return body;
};

export const showComplete = ()=>{
    const container = document.createElement("div");
            container.className = "gigauser-blocking-container";
            const error = document.createElement("div");
            error.className = "gigauser-tour-complete";
            error.innerText = "Congratulations! You're all set";
            container.appendChild(error);
            getBody().appendChild(container);
            setTimeout(() => {
                getBody().removeChild(container);
            }, 3000);
}
export const showError = (text?:string) => {
    const container = document.createElement("div");
    container.className = "gigauser-blocking-container";
    const error = document.createElement("div");
    error.className = "gigauser-tour-error";
    error.innerText = text?? "Oops! Looks like there was an issue in the tour.";
    container.appendChild(error);
    getBody().appendChild(container);
    setTimeout(() => {
        getBody().removeChild(container);
    }, 3000);
};

export const createSurroundingDivs = (target: HTMLElement) => {
    const boundingRect = target.getBoundingClientRect();

    // Click through divs first
    const top = document.createElement("div");
    top.className = "gigauser-clickthrough-opacity";
    top.style.left = "0px";
    top.style.top = "0px";
    top.style.width = "100%";
    top.style.height = boundingRect.y + "px";

    // Click through divs first
    const left = document.createElement("div");
    left.className = "gigauser-clickthrough-opacity";

    left.style.left = "0px";
    left.style.top = boundingRect.y + "px";
    left.style.width = boundingRect.x + "px";
    left.style.height = boundingRect.height + "px";

    // Click through divs first
    const bottom = document.createElement("div");
    bottom.className = "gigauser-clickthrough-opacity";

    bottom.style.left = "0px";
    bottom.style.top = boundingRect.y + boundingRect.height + "px";
    bottom.style.width = "100%";
    bottom.style.bottom = "0px";

    // Click through divs first
    const right = document.createElement("div");
    right.className = "gigauser-clickthrough-opacity";

    right.style.right = "0px";
    right.style.top = boundingRect.y + "px";
    right.style.left = boundingRect.x + boundingRect.width + "px";
    right.style.height = boundingRect.height + "px";
    return { top, left, bottom, right, boundingRect };
};

export function samePosition(rectA: DOMRect, rectB: DOMRect){
    // let rectA = a.getBoundingClientRect()
    // let rectB = b.getBoundingClientRect()
    logger.debug("rectangles", rectA, rectB)
    if (rectA.top !== rectB.top) return false
    if (rectA.left !== rectB.left) return false
    if (rectA.width !== rectB.width) return false
    if (rectA.height !== rectB.height) return false
    return true
}

