import { findElement, getDomElementDataFromTarget } from "../findElement";
import { TourStep } from "../../types/record";
import { createSurroundingDivs, getBody } from "../../utils/DOMUtils";
import "../tour.css";
import { BaseStep } from "./BaseStep";
import { createNextMessage } from "../message/messages";
import logger from "../../../utils/logger";

export class DefaultClick extends BaseStep {

    initiateTarget = () => {
        if (!this.target) return;
        this.target.addEventListener("mouseleave", this.disableClicks);
        this.target.addEventListener("click", this.completeStep);
    };

    createControls = () => {
        if (!this.blockingContainer) return;
        const {next, end, controls} = createNextMessage(this.step, this.stepIndex, this.target!, this.blockingContainer, {nextButton: false})
        this.endButton = end;
        this.endButton.onclick = this.removeStep
        this.clickEnabled.push(this.endButton)
        this.controls = controls;
    };

    removeStep = () => {
        logger.debug("removeStep here DefaultClick!")
        this.endTour()
    };

    playStep = async () => {

        const elem = await this.canPlay()
        if (elem) {
            // About to run a step!
            // Set the element
            this.target = elem;

            // Make everything unclickable
            this.createBlockingContainer();

            // Create the flashing box that serves a hole
            this.createBoundingBox();

            // Create step text
            this.createControls();

            // Set listeners on target
            this.initiateTarget();
            
            this.handleTourClicks()
            return 
        }
        
    };
}
