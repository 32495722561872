import React, { FC, PropsWithChildren, useCallback } from "react";
import { PlaceholderExtension } from "remirror/extensions";
import { TableExtension } from "@remirror/extension-react-tables";
import {
    EditorComponent,
    Remirror,
    TableComponents,
    ThemeProvider,
    useActive,
    useChainedCommands,
    useCommands,
    useRemirror,
    useRemirrorContext,
} from "@remirror/react";
import { AllStyledComponent } from "@remirror/styles/emotion";

import { BubbleMenu } from "./components/BubbleMenu";

import {
    CreateEditorStateProps,
    getDefaultDocNode,
    InvalidContentHandler,
    RemirrorContentType,
    RemirrorJSON,
    schemaToJSON,
} from "remirror";
import type { RemirrorProps } from "@remirror/react";

import "./TextEditor.css";
import { getExtensions } from "./utils/getExtensions";
import { OnChangeJSON } from "@remirror/react";
import { DOMSerializer } from "prosemirror-model";
import { SpecialImageExtension } from "./extensions/SpecialImageExtension";
import { PlainDoc } from "../../../core/types/guide";
import { cleanJSON, refillImages } from "./utils/cleanImageSrc";
import RichTextToolbar, { VoiceProps } from "./components/RichTextToolbar/RichTextToolbar";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../../utils/logger";
import { Size } from "../../../types/sizes";

export type TextEditorType = "extension" | "platform" | "videoTranscript"

export interface ReactEditorProps
    extends Pick<CreateEditorStateProps, "stringHandler">,
        Pick<
            RemirrorProps,
            "initialContent" | "editable" | "autoFocus" | "hooks"
        > {
    version: string;
    type: TextEditorType ;
    placeholder?: string;
    save?: (x: RemirrorJSON) => void;
    onEnhance?: (x: PlainDoc) => Promise<PlainDoc>;
    onGenerate?: () => void;
    scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    fixToolbar?:boolean;
    size?:Size

}

export interface TextEditorProps extends Partial<ReactEditorProps> {}
export type RichTextData = any;

export const TextEditor: FC<PropsWithChildren<TextEditorProps> & (VoiceProps | {})> = ({
    placeholder,
    stringHandler,
    children,
    version,
    type,
    onEnhance,
    onGenerate,
    save,
    scrollContainerRef,
    ...rest
}) => {
    const extensions = useCallback(
        () => [
            new PlaceholderExtension({placeholder: "Click here to begin typing"}),
            new TableExtension(),
            new SpecialImageExtension({
                enableResizing: false,
                editable: rest.editable,
                type: type ?? "platform"
            }),

            ...getExtensions(),
        ],
        [rest.editable]
    );

    const { manager } = useRemirror({ extensions, stringHandler });
    const onChange = useCallback((json: RemirrorJSON) => {
        // Store the JSON in localStorage
        save?.(json);
        //TODO: Optionally call the flag stuff here. 
    }, []);
    const serializer = DOMSerializer.fromSchema(manager.schema);
    logger.debug("SCHEMA ", getDefaultDocNode(manager.schema)?.toJSON())

    const onCopy = () => {
        const finalCopy = serializer.serializeFragment(
            manager.view.state.doc.content
        );
        logger.debug(serializer, finalCopy);
    };

    const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
        // Automatically remove all invalid nodes and marks.
        logger.debug("INVALID",json, invalidContent)
        return transformers.remove(json, invalidContent);
      }, []);

      var voiceProps = {}

      if("voice" in rest){
        voiceProps  = {voice: rest.voice, onClickVoice: rest.onClickVoice}
      }

    return (
        <ThemeProvider>
{            //@ts-ignore
}            <Remirror manager={manager} {...rest} onError={onError}>
                {/* <center><button onClick={onCopy}>Copy</button></center> */}
                <div className={`gigauser-TextEditor ${rest.size} ${rest.fixToolbar?"gigauser-text-editor-fix":""}`}>
                {
                    rest.editable?
                    <>
                    <RichTextToolbar
                        textEditorType={type || "platform"}
                        save = {save}
                        onEnhance=
                            {onEnhance ??
                            function (x: PlainDoc) {
                                return Promise.resolve(x);
                            }}
                        // onGenerate={onGenerate}
                        version= {version ?? "2023-03-12"}
                        scrollContainerRef={scrollContainerRef}
                        fixToolbar={rest.fixToolbar}
                        {...voiceProps}
                    />
                    </>
                    :null
                }
                

                {/* {rest.editable
                    ? <SetContentButton
                          onEnhance=
                              {onEnhance ??
                              function (x: PlainDoc) {
                                  return Promise.resolve(x);
                              }}
                          version= {version ?? "2023-03-12"}
                      />
                    : null}
                {rest.editable? <UndoButton></UndoButton>:null}
                {rest.editable? <RedoButton></RedoButton>:null} */}

                <EditorComponent />
                {rest.editable ? <BubbleMenu /> : null}
                <OnChangeJSON onChange={onChange}></OnChangeJSON>
                {/* <TableComponents /> */}
                {children}
                </div>
            </Remirror>
        </ThemeProvider>
    );
};
