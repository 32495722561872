import { setStyle } from "@remirror/core";
import { EditorView, NodeView, ProsemirrorNode } from "@remirror/pm";
import logger from "../../../../utils/logger";

/**
 * ZoomImageView is a NodeView for image. You can resize the image by
 * dragging the handle over the image.
 */
export class ZoomableImageView implements NodeView {
    dom: HTMLImageElement | HTMLDivElement;
    zoomedIn: boolean;
    zoomedInSource: string;
    editable: boolean;
    constructor(
        node: ProsemirrorNode,
        view: EditorView,
        getPos: () => number,
        imageStore: { [x: string]: string },
        editable: boolean,
        type: "extension" | "platform" | "videoTranscript"
    ) {
        this.zoomedIn = true;
        this.zoomedInSource = "";
        this.editable = editable;
        const regetURL = () => {
            let img = new Image();
            img.crossOrigin = "anonymous";
            const zoomCenter = node.attrs.metadata.zoomCenter;
            // logger.debug(aspectRatio, node.attrs.metadata);

            // create an alternative img url by copying to a canvas
            let zoomFactor = node.attrs.metadata.zoomFactor;
            img.onload = () => {
                const canv = document.createElement("canvas");
                canv.height = img.height / zoomFactor;
                canv.width = img.width / zoomFactor;
                const ctx = canv.getContext("2d");
                //@ts-ignore
                const randomOffset =
                    ((Math.random() * img.width) / zoomFactor) *
                    0.005 *
                    (Math.random() > 0.5 ? 1 : -1);
                const sx = Math.min(
                    Math.max(
                        img.width * zoomCenter.x -
                            img.width / (zoomFactor * 2) +
                            randomOffset,
                        // renderedWidth / 2 +
                        // randomOffset,
                        0
                    ),
                    img.width - img.width / zoomFactor
                );
                const sy = Math.min(
                    Math.max(
                        img.height * zoomCenter.y -
                            img.height / (zoomFactor * 2),
                        0
                    ),
                    img.height - img.height / zoomFactor
                );
                logger.debug(
                    "CANVAS",
                    sx,
                    sy,
                    img.width / zoomFactor,
                    img.height / zoomFactor,
                    0,
                    0,
                    img.width / zoomFactor,
                    img.height / zoomFactor
                );
                ctx?.drawImage(
                    img,
                    sx,
                    sy,
                    img.width / zoomFactor,
                    img.height / zoomFactor,
                    0,
                    0,
                    img.width / zoomFactor,
                    img.height / zoomFactor
                );
                // const newImageData = ctx?.getImageData(,,renderedWidth, renderedHeight)
                this.zoomedInSource = canv.toDataURL("image/jpeg", 1.0);
                imageStore[
                    node.attrs.metadata.stepId ??
                        node.attrs.metadata.stepID
                ] = this.zoomedInSource;
                // logger.debug("IMAGE STORE UPDATED", zoomFactor, this.zoomedInSource)
            };
            img.src = node.attrs.src;
        };
        if (node.attrs.metadata.zoomCenter) {

            //New screenshots
            let renderedWidth = type === "extension" ? 392: window.innerWidth * 0.4 ;

            const zoomCenter = node.attrs.metadata.zoomCenter;
            // logger.debug(aspectRatio, node.attrs.metadata);

            // create an alternative img url by copying to a canvas
            let zoomFactor = node.attrs.metadata.zoomFactor;
            if (!this.editable) {

                //Not editable here
                let img = new Image();
                logger.debug('LOADING IMAGE VIDEO', node.attrs.src)
                img.crossOrigin = "anonymous";
                img.onerror = (ev)=>{
                    logger.error("CANVAS VIDEO ERROR", ev)
                }
                img.onload = () => {
                    const canv = document.createElement("canvas");
                    canv.height = img.height / zoomFactor;
                    canv.width = img.width / zoomFactor;
                    const ctx = canv.getContext("2d");
                    //@ts-ignore
                    const randomOffset =
                        ((Math.random() * img.width) / zoomFactor) *
                        0.005 *
                        (Math.random() > 0.5 ? 1 : -1);
                    const sx = Math.min(
                        Math.max(
                            img.width * zoomCenter.x -
                                img.width / (zoomFactor * 2) +
                                randomOffset,
                            // renderedWidth / 2 +
                            // randomOffset,
                            0
                        ),
                        img.width - img.width / zoomFactor
                    );
                    const sy = Math.min(
                        Math.max(
                            img.height * zoomCenter.y -
                                img.height / (zoomFactor * 2),
                            0
                        ),
                        img.height - img.height / zoomFactor
                    );
                    logger.debug(
                        "CANVAS VIDEO",
                        sx,
                        sy,
                        img.width / zoomFactor,
                        img.height / zoomFactor,
                        0,
                        0,
                        img.width / zoomFactor,
                        img.height / zoomFactor
                    );
                    ctx?.drawImage(
                        img,
                        sx,
                        sy,
                        img.width / zoomFactor,
                        img.height / zoomFactor,
                        0,
                        0,
                        img.width / zoomFactor,
                        img.height / zoomFactor
                    );
                    // const newImageData = ctx?.getImageData(,,renderedWidth, renderedHeight)
                    this.zoomedInSource = canv.toDataURL("image/jpeg", 1.0);
                    imageStore[
                        node.attrs.metadata.stepId ?? node.attrs.metadata.stepID
                    ] = this.zoomedInSource;
                    
                    (this.dom as any).src = this.zoomedInSource;
                    
                };
                img.src = node.attrs.src;
                const inner = document.createElement("img");
                inner.setAttribute("src", node.attrs.src);
                inner.className += "gigauser-rich-editor-img";
                // inner.alt = "Loading...";
                setStyle(inner, {
                    width: "99%",
                    // height: renderedHeight + "px",
                    cursor: "pointer",
                    minWidth: "50px",
                    objectFit: "contain", // maintain image's aspect ratio
                });
                this.dom = inner;
            } else {

                //This is the editable one. 
                regetURL()
                const inner = document.createElement("div");


                // if (!wrapper){
                    
                // }
                const naturalX = node.attrs.metadata.naturalDimensions?.x;
                const naturalY = node.attrs.metadata.naturalDimensions?.y;
                const aspectRatio = naturalX / naturalY;
                // logger.debug(aspectRatio, node.attrs.metadata);

                const renderedHeight = renderedWidth / aspectRatio;
                inner.style.backgroundImage = "url(" + node.attrs.src + ")";
                inner.className += "gigauser-rich-editor-img";

                inner.addEventListener("mouseover", (event) =>{
                    logger.debug("mouseover image")
                    zoomIn.style.scale="1"
                    zoomOut.style.scale="1"
                })
    
                inner.addEventListener("mouseleave", (event) =>{
                    logger.debug("mouseleave image")
                    zoomIn.style.scale="0"
                    zoomOut.style.scale="0"
                })
    
                setStyle(inner, {
                    width: "99%",
                    height: renderedHeight + "px",
                    minWidth: "50px",
                    cursor: node.attrs.metadata.zoomCenter
                        ? "pointer"
                        : "default",
                    //objectFit: "contain", // maintain image's aspect ratio
                });
                this.dom = inner;
            }

            let zoomCheck = () => {
                // logger.debug(aspectRatio, node.attrs.metadata);
                if (!this.zoomedIn) {
                    if (!this.editable) {
                        let inner = this.dom as HTMLImageElement;
                        inner.src = node.attrs.src;
                        imageStore[
                            node.attrs.metadata.stepId ??
                                node.attrs.metadata.stepID
                        ] = node.attrs.src;
                    } else {
                        let inner = this.dom as HTMLDivElement;
                        inner.style.backgroundSize = "contain";
                        inner.style.backgroundPosition = "0px 0px";
                    }
                }
                if (this.zoomedIn) {
                    if (!this.editable) {
                        let inner = this.dom as HTMLImageElement;
                        inner.src = this.zoomedInSource;
                        imageStore[
                            node.attrs.metadata.stepId ??
                                node.attrs.metadata.stepID
                        ] = this.zoomedInSource;
                    } else {
                       

                        const naturalX =
                            node.attrs.metadata.naturalDimensions?.x;
                        const naturalY =
                            node.attrs.metadata.naturalDimensions?.y;
                        const aspectRatio = naturalX / naturalY;
                        // logger.debug(aspectRatio, node.attrs.metadata);

                        const renderedHeight = renderedWidth / aspectRatio;
                        let zoomFactor = node.attrs.metadata.zoomFactor;
                        this.dom.style.backgroundSize =
                            (zoomFactor * renderedWidth).toFixed(2) +
                            "px " +
                            (zoomFactor * renderedHeight).toFixed(2) +
                            "px";
                        const randomOffset =
                            Math.random() *
                            renderedWidth *
                            0.005 *
                            (Math.random() > 0.5 ? 1 : -1);
                        this.dom.style.backgroundPosition =
                            "-" +
                            Math.min(
                                Math.max(
                                    zoomFactor * renderedWidth * zoomCenter.x -
                                        renderedWidth / 2 +
                                        randomOffset,
                                    0
                                ),
                                //DON'T LET THE IMAGE WRAP AROUND
                                zoomFactor * renderedWidth - renderedWidth
                            ).toFixed(2) +
                            "px -" +
                            Math.min(
                                Math.max(
                                    zoomFactor * renderedHeight * zoomCenter.y -
                                        renderedHeight / 2,
                                    0
                                ),
                                zoomFactor * renderedHeight - renderedHeight
                            ).toFixed(2) +
                            "px";
                    }
                }
            };
            // zoomCheck();
            // this.dom = inner;
            if (this.editable) {
                zoomCheck()
                // this.dom.style.display = "float"

                //ZoomIn
                var zoomIn = document.createElement("img");
                zoomIn.className="gigauser-img-zoomIn"
                zoomIn.alt = "Zoom In";
                zoomIn.style.float = "right";
                zoomIn.style.width = "20px";
                zoomIn.style.height = "20px";
                zoomIn.style.borderRadius = "5px"
                zoomIn.style.padding = "3px"
                zoomIn.style.position="absolute"
                zoomIn.style.bottom="5px"
                zoomIn.style.right="5px"
                zoomIn.style.transition="0.3s"
                zoomIn.style.scale="0"
                zoomIn.style.backgroundColor="rgb(153 153 153)"
                zoomIn.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z'/%3E%3C/svg%3E"
                
                zoomIn.addEventListener("mouseover", (event) =>{
                    zoomIn.style.backgroundColor="rgb(44 43 43)"
                })

                zoomIn.addEventListener("mouseleave", (event) =>{
                    zoomIn.style.backgroundColor="rgb(153 153 153)"
                })

                //ZoomOut
                var zoomOut = document.createElement("img");
                zoomOut.alt = "Zoom Out";
                zoomOut.className="gigauser-img-zoomOut"
                zoomOut.style.float = "right";
                zoomOut.style.width = "20px";
                zoomOut.style.height = "20px";
                zoomOut.style.borderRadius = "5px"
                zoomOut.style.padding="3px"
                zoomOut.style.position="absolute"
                zoomOut.style.bottom = "5px";
                zoomOut.style.right = "36px"
                zoomOut.style.transition="0.3s"
                zoomOut.style.scale="0"
                zoomOut.style.backgroundColor="rgb(153 153 153)"
                zoomOut.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M18 10a1 1 0 01-1 1H3a1 1 0 110-2h14a1 1 0 011 1z'/%3E%3C/svg%3E"

                zoomOut.addEventListener("mouseover", (event) =>{
                    zoomOut.style.backgroundColor="rgb(44 43 43)"
                })

                zoomOut.addEventListener("mouseleave", (event) =>{
                    zoomOut.style.backgroundColor="rgb(153 153 153)"
                })

                this.dom.appendChild(zoomOut);
                this.dom.appendChild(zoomIn);
                zoomIn.onclick = (e) => {
                    e.preventDefault();
                    view.dispatch(
                        view.state.tr.setNodeAttribute(getPos(), "metadata", {
                            ...node.attrs.metadata,
                            zoomFactor: node.attrs.metadata.zoomFactor + 0.25,
                        })
                    );
                    zoomCheck();
                    regetURL();
                };
                zoomOut.onclick = (e) => {
                    e.preventDefault();
                    view.dispatch(
                        view.state.tr.setNodeAttribute(getPos(), "metadata", {
                            ...node.attrs.metadata,
                            zoomFactor: Math.max(
                                1,
                                node.attrs.metadata.zoomFactor - 0.25
                            ),
                        })
                    );
                    zoomCheck();
                    regetURL();
                };
            }
            this.dom.onclick = () => {
                if (!this.editable) {
                    this.zoomedIn = !this.zoomedIn;
                    zoomCheck();
                } else {
                    // We increment zoom factor and save in node as well
                }
            };
        } else {

            //This is for old screenshots!

            const inner = document.createElement("img");


            
            inner.setAttribute("src", node.attrs.src);
            inner.className += "gigauser-rich-editor-img";

            // var css = `.gigauser-rich-editor-img:hover .gigauser-img-zoomOut {
            //     display: block;
            //     background-color: pink
            // }`;

            // var style = document.createElement('style') as any;

            // if (style.styleSheet) {
            //     logger.debug("basic style")

            //     style.styleSheet.cssText = css;
            // } else {
            //     logger.debug("appending style")
            //     style.appendChild(document.createTextNode(css));
            // }
            
            // const headElements = document.getElementsByTagName('head') as any
            // for (var head of headElements){
            //     logger.debug("head: ", head)

            //     head.appendChild(style);
            // }



            setStyle(inner, {
                width: "99%",
                minWidth: "50px",
                objectFit: "contain", // maintain image's aspect ratio
                position: "relative"
            });
            this.dom = inner;
        }
    }
}
