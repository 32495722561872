

const watchIcon = (color?:string) =>{

    const fill = color || "#000000"
    
    return(
        <svg width="100%" height="50%" viewBox="0 0 45 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="25.5" height="18" rx="1.5" stroke={fill} strokeWidth="3"/>
            <path d="M39 14.1962L30 9L39 3.80385V14.1962Z" stroke={fill} strokeWidth="3"/>
        </svg>
    )
}

export default watchIcon