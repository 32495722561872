
import { Collection } from "../core/types/collections"
// import { Id } from "../core/types/baseTypes"
import { GigaUserApi } from "@giga-user-fern/api";

import { Permission, User } from "./user"
import { GuidePreview } from "../core/types/guide";

export type Item = {
    type: "Collection", 
    entry: Collection
} | {
    type: "Guide", 
    entry: GuidePreview
}

export type Folder = {
    name : 'My Library'
    objectID: 'Folder_library',
    parentID: null,
    description: 'Your library',
    permission: 'owner'
} | {
    name : 'Shared Files'
    objectID: 'Folder_shared',
    parentID: null,
    description: 'Files shared with you will appear here',
    permission: 'owner'
} | {
    name: string, 
    objectID: string,
    parentID: string //This is 'root' for root folder files
    description?: string | undefined, 
    icon?:string,
    createdAt?: string
    lastOpened?: string
    permission: Permission
} | {
    name: 'Root',
    objectID: 'Folder_root', 
    parentID: null, 
    description: 'The root directory',
    permission: "owner"
}

export type ReadingDocument = {
    name: string,
    description?:string,
    objectID: string,
    icon?: string
    parentID: string //This is 'root' for root folder files
    format: 'PDF' | 'ePub'
    createdAt: string
    lastOpened?: string
    permission: Permission
    url?: string

    file?: {
        bucket: string
        region: string
        key: string
    }, 
    customSetup?: {
        pageOffset?: number,
    }
}

export type WritingDocument = {
    name: string,
    description?:string,
    objectID: string,
    icon?: string
    parentID: string //This is 'root' for root folder files
    createdAt?: string
    lastOpened?: string
    permission: Permission
    nbObj?: any
    activeEditorSub?: string
    requested?: {
        sub: string
        sessionID: string
    }
    activeEditorSesion?: string
    owner?: string

}

export type ObjectUser = (Folder | ReadingDocument | WritingDocument)

export type DocumentInfoProps = {
    fileType: "Folder"
    objectData: Folder
} | {
    fileType: "Reading"
    objectData: ReadingDocument
} | {
    fileType : "Writing"
    objectData: WritingDocument
}

export type FileType = "Folder" | "Reading" | "Writing"

export type Comment = {
    user: User, //this type is there in types folder (check it) 
    createdAt: Date,
    content: string, 
    id: string, 
}

export type Thread = {
	id : string
	comments: Comment[]
}


const myLibrary : Folder= {
    name : 'My Library',
    objectID: 'Folder_library',
    parentID: null,
    description: 'Your library',
    permission: "owner"
}
const sharedFiles : Folder = {
    name : 'Shared Files',
    objectID: 'Folder_shared',
    parentID: null,
    description: 'Files shared with you will appear here',
    permission: "owner"

}

const rootFolder: Folder ={
    name: 'Root',
    objectID: 'Folder_root', 
    parentID: null, 
    description: 'The root directory',
    permission: "owner"
}

const rootCollection: Collection = {
    id: GigaUserApi.baseTypes.Id("Collection_root"), 
    name: "Home", 
    description: "The root collection",
    sequenceNumber: 1,
    parentId: undefined,
}


const sampleReading: ReadingDocument = {
    name: 'Sample Reading',
    objectID: "id1", 
    parentID: "id2", 
    createdAt: "sdsdf", 
    format: "PDF", 
    permission: "owner",
}


const unravelItem: (item: Item) =>{
    name: string, 
    description?: string, 
    parentId?: GigaUserApi.Id,

} = (item) =>{

    var name: string = ""
    var description: string | undefined
    var parentId: GigaUserApi.Id | undefined

    if(item.type==="Collection"){
        name=item.entry.name
        description = item.entry.description
        parentId = item.entry.parentId
    }

    else if(item.type==="Guide"){
        name = item.entry.header.name
        description = item.entry.header.description
        parentId = item.entry.parentId
    }

    return {
        name, 
        description, 
        parentId
    }
}

export {myLibrary, sharedFiles, rootFolder, sampleReading, rootCollection, unravelItem} 
