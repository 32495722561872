import React from 'react';
import './SimpleTabs.css'
import Icon from '../../Icon/Icon';
import { getBrandingColor } from '../../../core/utils/styleUtils';
import { Size } from '../../../types/sizes';

type Tab = 
// {
//     icon: (color?: string) => JSX.Element, 
//     text?: string, 
//     hidden?:boolean,
// } | 
{
    icon?:(color?: string) => JSX.Element, 
    text: string, 
    hidden?:boolean
}

type SimpleTabsProps = ({
    activeTab: number,
    setActiveTab: (t: number) => void ,
} | {
    activeTab: string,
    setActiveTab: (t: string) => void  ,
}) & {
    size?:Size
    tabs: Tab[], 
};

const SimpleTabs:React.FC<SimpleTabsProps> = (props) => {

    const brandColor = getBrandingColor()
    
    return (
        <div className='gigauser-simple-tabs'>
            {
                props.tabs.map((tab, index) => {
                    const isActive = props.activeTab==index ||  props.activeTab==tab.text
                    
                    return(
                    
                    <div style={{display: tab.hidden?"none":"flex"}} key={tab.text} className={`gigauser-simple-tab gigauser-${props.size} ${isActive?"gigauser-active-tab":""}`} 
                        onClick={() =>{
                            // @ts-ignore
                            if(typeof(props.activeTab)==="number") props.setActiveTab(index)
                            // @ts-ignore
                            else props.setActiveTab(tab.text)
                        }}
                    >
                        {
                            tab.icon?
                            <Icon className={`gigauser-simple-tab-icon ${props.size}`}>
                                {tab.icon(isActive?brandColor:"#727272")}
                            </Icon>
                            :null
                        }
                        {tab.text}
                    </div>   
                    )
                } 
            )}
            
           

        </div>
    )
}
export default SimpleTabs;