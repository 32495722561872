import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {helpSaver as saver} from "@gigauser/common/src/network/saver"
import HelpCenter, { HelpCenterProps } from "@gigauser/common/src/help-center/HelpCenter/HelpCenter"
import { GigaUserApi } from '@giga-user-fern/api';
import { GuideData } from '@giga-user-fern/api/types/api/resources/guides';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Collection } from '@giga-user-fern/api/types/api/resources/collections';
import logger from '@gigauser/common/src/utils/logger';
import { isProdDomain } from '@gigauser/common/src/help-center/utils/routeUtils';
import GridLoader from '@gigauser/common/src/ui/loaders/GridLoader/GridLoader'
import { getBrandingColor } from '@gigauser/common/src/core/utils/styleUtils';
import useWindowSize from '@gigauser/common/src/hooks/useWindowSize';
import ExtHelpCenter from './templates/v2/ExtHelpCenter';
import ExtHelpCenterContext from './templates/v2/ExtHelpCenterContext';
import { Provider } from 'react-redux';
import store from './redux/store';

//9ca802c0-d09f-45ba-82fb-cd3095c9d754
export type HelpCenterPage = "Home" | "Browser"


type AppProps = {
    page?: HelpCenterPage
};

const App:React.FC<AppProps> = (props) => {
    
    const initOrgId = process.env.NODE_ENV==="development"?"9ca802c0-d09f-45ba-82fb-cd3095c9d754":null
    const [orgId, setOrgId] = useState<string|null>(null)

    logger.debug("orgId: ", orgId)

    const {device} = useWindowSize()

    const[loading, setLoading] = useState(true)

    const init = async(orgId:string) =>{

        // const r = await saver.initialiseFromOrgId(orgId)
        logger.debug("saver is now: ", saver, )
        setLoading(false)

    }

    const [searchParams, setSearchParams] = useSearchParams();

    

    useEffect( () =>{
        if (saver.organization){
            return
        }
        if (isProdDomain()){
            saver.initialiseFromHostname().then((org)=>{
                if (org.ok){
                    setOrgId(org.body.id)
                    logger.debug("got org: ", org.body.id)
                    setLoading(false)
                }
                else{
                     // Check token
                    if (org.error.error === "InvalidSessionError"){
                        let urlToken = searchParams.get('token')
                        if (!urlToken){
                            window.location.replace("https://" + org.error.content.redirectUrl + "/?redirect_uri=" + window.location.href)
                        }
                        else{
                            saver.validateToken(urlToken).then(
                                (resp)=>{
                                    searchParams.delete("token")
                                    setSearchParams(searchParams)
                                    saver.initialiseFromHostname().then((org)=>{
                                        if (org.ok){
                                            setOrgId(org.body.id)
                                            logger.debug("got org: ", org.body.id)
                                            setLoading(false)
                                        }
                                        
                                    })
                                    
                                    return
                                }
                            ).catch((e)=>{
                                // TODO: Display some error and say try again
                                // Perhaps set some state? 
                                urlParams.delete("token")
                            })
                        }
                        return
                    }
                }
                
            })
            
            return
        }

        const urlParams = new URLSearchParams(window.location.search);
        var urlOrgId = urlParams.get('org')
        if(!urlOrgId && process.env.NODE_ENV==="development"){
            urlOrgId="9ca802c0-d09f-45ba-82fb-cd3095c9d754"
        }

        setOrgId(urlOrgId)
        logger.debug("got org: ", urlOrgId)
        if(urlOrgId) {
            init(urlOrgId)
        }
        
    }, [searchParams, setSearchParams])

    if(!orgId){
        logger.error("invalid URL!")
        return(
            <GridLoader center color={getBrandingColor()} />
        )
    }

    if(loading){
        return (
            <GridLoader center color={getBrandingColor()} />
        )
    }


    const helpCenterProps : HelpCenterProps = {
        navigator:useNavigate,

        search:saver.search,

        getOrganization:() => {return saver.organization},
        
        fetchAllGuidePreviews: async() =>{
            const guidePreviews = await saver.fetchAllGuidePreviews()
            return guidePreviews
        },

        fetchAllChildren: async (collectionId?: string) => {
            const {guides, collections} =  await saver.fetchAllChildren(collectionId, true)
            return Promise.resolve({collections: collections, guidePreviews: guides})
        },

        fetchGuidePreview: async(id:GigaUserApi.Id ) =>{
            const guidePreview = await saver.fetchGuidePreview(id)
            return guidePreview;
        },

        getPath : async(id: GigaUserApi.Id) => {
            const path = await saver.getPath(id)
            return path
        },

        fetchGuideData:async (id: GigaUserApi.Id) => {
            const guideData = await saver.fetchGuideData(id) as GuideData
            return guideData;
        },

        close:() =>{}
    }
    

    return (

            <div className="App">
                {
                    device==="laptop"?
                    <Provider store={store}>
                        <ExtHelpCenterContext.Provider value={helpCenterProps}>
                            <ExtHelpCenter page={props.page || "Home"} />
                        </ExtHelpCenterContext.Provider>
                    </Provider>
                    :
                    <HelpCenter {...helpCenterProps} />   
                }
            </div>

    );
}

export default App;
