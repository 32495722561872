import { useActive, useChainedCommands, useCommands, useRemirrorContext } from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import hr from "../../../../../../assets/svgs/text-editor-toolbar/hr"
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import "./HRButton.css"
type ListOptionsProps = {};



const HRButton: React.FC = () => {
    const active = useActive();
    const commands = useChainedCommands() as any;
    

    const handler = ()=>{
        commands.insertHorizontalRule().focus().run()
    }


    return (
        
                <ToolbarButton
                active={()=>false}
                handler={handler}
                hoverTip={"Horizontal Rule"}
                source={hr()}/>

        
    );
};
export default HRButton;
