import {
    ApplySchemaAttributes,
    command,
    CommandFunction,
    ExtensionTag,
    InputRule,
    isElementDomNode,
    keyBinding,
    KeyBindingProps,
    NodeExtension,
    NodeExtensionSpec,
    NodeSpecOverride,
    toggleWrap,
    lift,
    wrapIn,
    getActiveNode
  } from '@remirror/core';
  import { ExtensionBlockquoteMessages as Messages } from '@remirror/messages';
  import { wrappingInputRule } from '@remirror/pm/inputrules';
  import type { PasteRule } from '@remirror/pm/paste-rules';
// import { getActiveNode } from 'remirror';
  
  const BLOCKATTRIBUTE = 'data-gigauser-blockquote-color'
  /**
   * Add the blockquote block to the editor.
   */
  export class SpecialBlockquoteExtension extends NodeExtension {
    get name() {
      return 'blockquote' as const;
    }
  
    createTags() {
      return [ExtensionTag.Block, ExtensionTag.FormattingNode];
    }
  
    createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
      return {
        content: 'block+',
        defining: true,
        draggable: false,
        ...override,
        attrs: {...extra.defaults(), type: {default: 'gray'}},
        parseDOM: [
          { tag: 'blockquote', getAttrs: extra.parse, priority: 100 },
          {tag: `blockquote[${BLOCKATTRIBUTE}]`, getAttrs: (element)=>{
            if (isElementDomNode(element)){
                return {...extra.parse(element), type: element.getAttribute(BLOCKATTRIBUTE)}
            }
            else{
                return {...extra.parse(element)}
            }
          }},
          ...(override.parseDOM ?? []),
        ],
        toDOM: (node) => ['blockquote', {...extra.dom(node), class: node.attrs.type, [BLOCKATTRIBUTE]: node.attrs.type}, 0],
      };
    }
  
    /**
     * Toggle the blockquote for the current block.
     *
     * If none exists one will be created or the existing blockquote content will be
     * lifted out of the blockquote node.
     *
     * ```ts
     * commands.toggleBlockquote();
     * ```
     */
    @command({
      icon: 'doubleQuotesL',
      description: ({ t }) => t(Messages.DESCRIPTION),
      label: ({ t }) => t(Messages.LABEL),
    })
    toggleBlockquote(color: string | undefined): CommandFunction {
        const type = this.type
        return (props) => {
            const { tr, state } = props;
            const activeNode = getActiveNode({ state: tr, type });
        
            if (activeNode) {
                // Check node data
                const actualNode = tr.doc.nodeAt(activeNode.pos)
                if (actualNode?.attrs.type === color || !color){
                    // Deactivate this and leave
                    return lift(props);
                }
                // Otherwise, we need to reset data
                props.dispatch?.(tr.setNodeAttribute(activeNode.pos, "type", color))
                return true
            }
            if (!color){
              return true
            }
            return wrapIn(type, {type:color})(props);
          };
    }

    // @command()
    // changeBlockQuoteType(color: string): CommandFunction{
    //     // If the current node is NOT of blockquote type, 
    //     // we just have to run toggleWrap with the additional 
    // }
  
    // @keyBinding({ shortcut: 'Ctrl->', command: 'toggleBlockquote' })
    // shortcut(props: KeyBindingProps): boolean {
    //   return this.toggleBlockquote()(props);
    // }
  
    createInputRules(): InputRule[] {
      return [wrappingInputRule(/^\s*>\s$/, this.type)];
    }
  
    createPasteRules(): PasteRule {
      return {
        type: 'node',
        nodeType: this.type,
        regexp: /^\s*>\s$/,
        startOfTextBlock: true,
      };
    }
  }
  

  