import { createRoutesFromElements,  Route } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import App from "./App";

const HardRedirect : React.FC = ()=>{
	
    window.location.replace('/folders/Collection_root/')
    return (<></>)
} 


const routes = 

    createRoutesFromElements(<>

        <Route path="/folders/:objectID" 
            element={
                <App page="Browser"></App>
            }
		/>

        <Route path="/guide/*" 
            element={
                <App page="Browser" />
            }
		/>

        {/* <Route path="/" element={
                <App page="Home" />
            }>

        </Route> */}


		<Route path = "*" element={<HardRedirect/>}/>
        
    </>)



export { routes };
export function CatchBoundary() {}
