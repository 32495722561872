import logger from "../../utils/logger";
import { adjustColorForDarkMode } from "./styleUtils2";
import { makeColorDarkModeCompatible } from "./styleUtils3";

export function hexToRgb(hex: string) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `${r}, ${g}, ${b}`;
}

export function setBranding(hex?: string) {
    try {
        let root = document.documentElement;

        let brandColor = hex || "#d43f8c";

        // brandColor = "#4f46e5"

        const brandColorRgb = hexToRgb(brandColor);

        root.style.setProperty("--gigauser-brand-color", brandColor);
        root.style.setProperty("--gigauser-brand-color-rgb", brandColorRgb);
        root.style.setProperty("--gigauser-light-brand-color", lightenColor(brandColor, 0.8))

        const darkModeColor = lightenColorForDarkMode(brandColor, 60)
        // // const darkModeColor = adjustColorForDarkMode(brandColor, 60, 80)
        // const darkModeColor = makeColorDarkModeCompatible(brandColor)

        root.style.setProperty("--gigauser-darkMode-brand-color", darkModeColor );
        root.style.setProperty("--gigauser-darkMode-brand-color-rgb", hexToRgb(darkModeColor));

    } catch (e) {
        logger.debug("COULD NOT FETCH ELEMENT");
    }
}

export function getBrandingColor(theme="gigauser-light") {
    return getComputedStyle(document.documentElement).getPropertyValue(
        theme==="gigauser-light"?"--gigauser-brand-color":"--gigauser-darkMode-brand-color"
    );
}

export function darkenHexColor(color: string, factor: number) {
    //If factor <1 --> darken

    if (factor > 1) throw new Error("Invalid factor > 1");

    // Parse the input color string into RGB values
    var r = parseInt(color.substring(1, 3), 16);
    var g = parseInt(color.substring(3, 5), 16);
    var b = parseInt(color.substring(5, 7), 16);

    // Multiply each RGB value by the specified factor
    r = Math.round(r * factor);
    g = Math.round(g * factor);
    b = Math.round(b * factor);

    // Convert the RGB values back to a hex color string
    var hex = "#" + r.toString(16) + g.toString(16) + b.toString(16);

    return hex;
}

export function lightenColor(color: string, factor: number) {
    if (factor > 1) throw new Error("Invalid factor > 1");

    // Parse the input color string into RGB values
    var r = parseInt(color.substring(1, 3), 16);
    var g = parseInt(color.substring(3, 5), 16);
    var b = parseInt(color.substring(5, 7), 16);

    // Calculate the new RGB values by adding a fraction of the difference
    // between the maximum value and the current value for each color channel
    r += Math.round((255 - r) * factor);
    g += Math.round((255 - g) * factor);
    b += Math.round((255 - b) * factor);

    // Make sure the RGB values are within the valid range (0-255)
    r = Math.min(r, 255);
    g = Math.min(g, 255);
    b = Math.min(b, 255);

    // Convert the RGB values back to a hex color string
    var hex = "#" + r.toString(16) + g.toString(16) + b.toString(16);

    return hex;
}


function lightenColorForDarkMode(hexCode: string, percentage: number): string {
    // Remove the '#' if present
    hexCode = hexCode.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hexCode.slice(0, 2), 16);
    const g = parseInt(hexCode.slice(2, 4), 16);
    const b = parseInt(hexCode.slice(4, 6), 16);

    // Calculate new lightened values
    const newR = Math.min(255, r + Math.round((255 - r) * percentage / 100));
    const newG = Math.min(255, g + Math.round((255 - g) * percentage / 100));
    const newB = Math.min(255, b + Math.round((255 - b) * percentage / 100));

    // Convert back to hex
    const newHex = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

    return newHex;
}


