import React, { useEffect, useRef, useState } from "react";
import "./Video.css";
import { GuideData } from "../../../core/types/guide";
import { oembed } from "@loomhq/loom-embed";
import { OEmbedInterface } from "@loomhq/loom-embed/dist/.types/oembed";
import logger from "../../../utils/logger";

type VideoProps = {
    video: GuideData["video"];
    videoSrc?: string
};
/**
 *  calculateMediaDuration()
 *  Force media element duration calculation.
 *  Returns a promise, that resolves when duration is calculated
 **/
function calculateMediaDuration(media: HTMLMediaElement) {
    return new Promise((resolve, reject) => {
        media.onloadedmetadata = function () {
            // set the mediaElement.currentTime  to a high value beyond its real duration
            media.currentTime = Number.MAX_SAFE_INTEGER;
            // listen to time position change
            media.ontimeupdate = function () {
                media.ontimeupdate = function () {};
                // setting player currentTime back to 0 can be buggy too, set it first to .1 sec
                media.currentTime = 0.1;
                media.currentTime = 0;
                // media.duration should now have its correct value, return it...
                resolve(media.duration);
            };
        };
    });
}

const Video: React.FC<VideoProps> = (props) => {
    const recorderType: "default" | "loom" | undefined = props.video.metadata?.recorderType;
    const vidRef = useRef<HTMLVideoElement>(null);
    const [button, setButton] = useState(true)
	const [videoHTML, setVideoHTML] = useState<string>("");


    const handlePlay = () => {
        setButton(false);
        if (vidRef.current) {
            vidRef.current.play();
        }
    };

    const handlePause = () => {
        if (vidRef.current) {
            vidRef.current.pause();
        }
    };



    useEffect(() => {

        logger.debug("vidref.current: ", vidRef.current)
        logger.debug("src: ", props.video.metadata?.generatedVideo?.src)

        if (vidRef.current && (recorderType === "default" || !recorderType)) {
            calculateMediaDuration(vidRef.current);
        }
		else if (recorderType === "loom"){
			oembed(props.video.src).then((obj:OEmbedInterface)=>{
				setVideoHTML(obj.html)
			})
		}
    }, []);


    

    
    if (recorderType === "default" || !recorderType) {
        return (
            <div className="gigauser-video-container" 
                // onClick={button?handlePlay:undefined}
            >

                {/* <div
                    style={{visibility: (button)?"visible":"hidden"}} 
                    className={`gigauser-play-button`}></div> */}

              <video
                    ref={vidRef}
                    preload="metadata"
                    controls
                    className={`gigauser-editor-video `}
                    src={props.videoSrc || props.video.src}
                    // onPlay={handlePlay}
                    // onPause={handlePause}

                >

                </video>
            </div>
            
        );
    } else if (recorderType === "loom") {
		return (
			<div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
		)
    } else {
        return <></>;
    }
};
export default Video;
