import React, {useState} from 'react';

import './Icon.css'

type IconProps = {
    children: React.ReactNode,
    className?: string,
    hoverTip?:string,
    hoverPosition?: "bottom" | "top",
    
}& React.ImgHTMLAttributes<HTMLImageElement>;

const Icon:React.FC<IconProps> = ({className, hoverTip, hoverPosition="bottom", ...props}) => {

    const [isHovering, setIsHovering] = useState<boolean>(false)
    
    const onMouseEnter = (e:any) =>{
        setIsHovering(true)
    }

    const onMouseLeave = () =>{
        setIsHovering(false)
    }

    const hoverTipComponent = isHovering && hoverTip ? <div className={`HoverTip ${hoverPosition}`}>
        <div className='hovertip-text'>{hoverTip}</div>
        {/* {shortcut ? <div className='hovertip-shortcut'>{shortcut}</div> : null} */}
    </div> : null
    
    return (

        <div className="gigauser-Icon-parent">
            <div className={`gigauser-Icon ${className} `} {...props} onMouseEnter={(e)=>{onMouseEnter(e)}} onMouseLeave={onMouseLeave}>
                {props.children}
            </div>
            {hoverTipComponent}
        </div>
        
    )
}
export default Icon;