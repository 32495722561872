

import React from 'react';
import './LoadingRing.css'

type LoadingRingProps = {
    color?: string
};

const LoadingRing:React.FC<LoadingRingProps> = (props) => {

    var color = props.color || "#ffffff"
    
    return (
        <div className="gigauser-LoadingRing" style={{zoom: 0.2, marginLeft: '30px'}}>
        <div
            style={{
                borderColor: `${color} transparent transparent transparent`,
            }}
        ></div>
        <div style={{borderColor: `${color} transparent transparent transparent`,}}></div>
        <div style={{borderColor: `${color} transparent transparent transparent`,}}></div>
        <div style={{borderColor: `${color} transparent transparent transparent`,}}></div>
        </div>
    )
}
export default LoadingRing;


