import { Collection } from "@gigauser/common/src/core/types/collections"
import { Guide, GuideData, GuidePreview } from "@gigauser/common/src/core/types/guide"
import { rootCollection } from "@gigauser/common/src/types/files"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { GigaUserApi } from "@giga-user-fern/api"

export interface GuideNode {
    type: "guide"
    guide: GuidePreview
}

export interface CollectionNode {
    type: "collection" 
    collection: Collection
    collapsed: boolean
    children?: Tree //if it is loaded, there will be an empty array here. Otherwise it will be undefined
}

export interface hcCurrentGuide {
    id: GigaUserApi.Id, 
    guidePreview: GuidePreview,
    guideData?: GuideData //if guideData is not passed, then we will be loading. 
}

export type Tree = (CollectionNode | GuideNode)[] 

type HelpCenterState  = {

    tree: Tree, 
    currentGuide: hcCurrentGuide | null , 
    collectionPath: [Collection, ...Collection[]], 

}

const initialState: HelpCenterState = {

    tree: [],
    currentGuide: null,
    collectionPath: [rootCollection],

}

export const hcSlice = createSlice({
    name: "hc",
    initialState, 
    reducers: {

        setTree: (state, action: PayloadAction<Tree>) =>{
            const tree = action.payload
            console.log("setting Tree: ", tree)
            state.tree = tree
        }, 

        updateNodeInTree: (state, action: PayloadAction<CollectionNode>) =>{

            // Update a node in the tree
            const node = action.payload

            // Loop through the tree, until this folder is found.
            const exploreNode = (currNode : CollectionNode | GuideNode)=>{

                if(currNode.type==="guide"){
                    return currNode
                }

                else if (currNode.collection.id === node.collection.id){
                    //found the matching node! swap it out with the new one
                    return action.payload
                }
                else{
                    if(currNode.children){
                        currNode.children = currNode.children.map(exploreNode)
                    }
                    else{
                        currNode.children = undefined
                    }
                    return currNode
                }
            }
            
            const copy = [...state.tree]
            state.tree = copy.map(exploreNode)

        }, 

        setCurrentGuide: (state, action: PayloadAction<hcCurrentGuide | null>) =>{
            state.currentGuide = action.payload
        }, 
        
        setCollectionPath: (state, action: PayloadAction<[Collection, ...Collection[]]>) =>{
            console.log("setting Collection path : ", action.payload)
            state.collectionPath = action.payload
        }, 

    },

})

export const {setTree, updateNodeInTree, setCurrentGuide, setCollectionPath} = hcSlice.actions

export const selectCurrentCollection = (state: RootState) => state.hc.collectionPath.at(-1)
export default hcSlice.reducer





