
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import "./ToolbarButton.css"

type ToolbarButtonProps = {
    active: ()=>boolean
    handler: ()=>void
    hoverTip?: string
    source: JSX.Element
    backgroundColor?: string
};



const ToolbarButton: React.FC<ToolbarButtonProps> = (props) => {

    return (
        <div onClick = {props.handler}  style={{display: "flex", alignItems: "center", justifyContent: "center" ,backgroundColor: props.active()? "pink":props.backgroundColor ?? "white"}}>
            <Icon hoverTip = {props.hoverTip}  className={`gigauser-texteditor-toolbar-button ${props.active()}`}>
                {props.source}
            </Icon>
        </div>
    );
};
export default ToolbarButton;
