import { useActive, useChainedCommands, useCommands, useRemirrorContext } from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import bold from "../../../../../../assets/svgs/text-editor-toolbar/editor-bold"
import italic from "../../../../../../assets/svgs/text-editor-toolbar/editor-italic"
import underline from "../../../../../../assets/svgs/text-editor-toolbar/editor-underline"
import strikethrough from "../../../../../../assets/svgs/text-editor-toolbar/editor-strikethrough"

import ToolbarButton from '../ToolbarButton/ToolbarButton';
import ListOptions from "../ListOptions/ListOptions";
type TextStyleOptionsProps = {};



const TextStyleOptions: React.FC<TextStyleOptionsProps> = () => {
    const active = useActive();
    const commands = useChainedCommands() as any;
    const items = [{
        label: "bold",
        active: () => active.bold(),
        handler: () => commands.toggleBold().focus().run(),
        hoverTip: {
            text: "Bold",
            shortcut: "CmdOrCtrl+B",
        },
        source: bold()
    },
    {
        label: "italic",
        active: () => active.italic(),
        handler: () => commands.toggleItalic().focus().run(),
        hoverTip: {
            text: "Italic",
            shortcut: "CmdOrCtrl+I",
        },
        source: italic()
    },
    {
        label: "underline",
        active: () => active.underline(),
        handler: () => commands.toggleUnderline().focus().run(),
        hoverTip: {
            text: "Underline",
            shortcut: "CmdOrCtrl+U",
        },
        source: underline()
    },
    {
        label: "strikethrough",
        active: () => active.strike(),
        handler: () => commands.toggleStrike().focus().run(),
        hoverTip: {
            text: "Strikethrough",
            shortcut: "CmdOrCtrl+D",
        },
        source: strikethrough()
    }
]
    


    return (
        <div style={{display: "flex", backgroundColor: "white"}}>
            {items.map(x=>
                <ToolbarButton
                active={x.active}
                handler={x.handler}
                hoverTip={x.hoverTip.text}
                source={x.source}/>
            )}
            
            <ListOptions></ListOptions>
        </div>
        
    );
};
export default TextStyleOptions;
